import { Autocomplete, Box, TextField } from '@mui/material';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const GasQuestionEdit: React.FC = () => {
	const {
		saveButtonProps,
		control,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('question_text', {
						required: 'Required',
					})}
					error={!!errors.question_text}
					helperText={errors.question_text?.message ? `${errors.question_text?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Text"
					name="question_text"
					multiline
					rows={4}
					autoFocus
				/>
				<Controller
					control={control}
					name="status"
					rules={{
						required: 'Required',
					}}
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['draft', 'reviewed', 'rejected']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Status'}
									margin="normal"
									variant="outlined"
									error={!!errors.status}
									helperText={errors.status?.message ? `${errors.status?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
			</Box>
		</Edit>
	);
};

import React from 'react';
import { useDataGrid, List, DeleteButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';


export const ContentMediaList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'entity_id',
			headerName: 'EntityId',
			width: 100,
		},
		{
			field: 'entity_type',
			headerName: 'EntityType',
			width: 100,
		},
		{
			field: 'url_original',
			headerName: 'UrlOriginal',
			width: 600,
		},
		{
			field: 'url_small',
			headerName: 'UrlSmall',
			width: 600,
		},
		{
			field: 'tags',
			headerName: 'Tags',
			width: 300,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
				return (<>
					<DeleteButton hideText recordItemId={row.id} />
				</>);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

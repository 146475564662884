import { useState } from 'react';
import '../../styles/main.css';
import axios from 'axios';
import { MAIN_API_SERVER } from './../../constants';
import ToastHelper from '@helpers/toast_helper';

const GenerateLink = () => {
	const [userId, setUserId] = useState<any>('');
	const [userIds, setUserIds] = useState<any>('');
	const [instituteId, setInstituteId] = useState<any>('');
	const [classCode, setClassCode] = useState<any>('');
	const [assignmentCode, setAssignmentCode] = useState<any>(null);
	const [copy, setcopy] = useState<any>(false);
	const [forceFul, setForceFul] = useState<any>(false);
	//const {institute, section, start_date, end_date} = useParams();
	var url = `${MAIN_API_SERVER}/api/operations/generate_link`;

	const generateLinks = (userId: any, assignmentCode: any, copy: any, forceful: any) => {
		axios
			.post(url, {
				user_id: Number(userId),
				user_ids: userIds,
				institute_id: Number(instituteId),
				assignment_code: assignmentCode,
				class_code: classCode,
				copy: copy,
				forceful: forceful,
			})
			.then(function (response) {
				new ToastHelper().showInfo(response?.data?.data?.code || 'Successfull', 6000, false);
			})
			.catch((err: any) => {
				new ToastHelper().showError(err?.message || 'Oops! Error in generating link right now. Please try again after sometime.');
			});
	};

	return (
		<div className="input_leaderboard">
			<label>User Id:</label>
			<input disabled={instituteId !== ''} className="leaderboard_options" type="number" onChange={(e) => setUserId(e.target.value)} />
			<label>User Ids:</label>
			<input disabled={instituteId !== ''} className="leaderboard_options" type="text" onChange={(e) => setUserIds(e.target.value)} />
			<label>Institute Id:</label>
			<input disabled={userId !== ''} className="leaderboard_options" type="number" onChange={(e) => setInstituteId(e.target.value)} />
			<label>Class Code:</label>
			<input disabled={userId !== ''} className="leaderboard_options" type="text" onChange={(e) => setClassCode(e.target.value)} />
			<label>Assignment Code:</label>
			<input className="leaderboard_options" onChange={(e) => setAssignmentCode(e.target.value)} />
			<label>Copy:</label>
			<select
				className="leaderboard_options"
				onChange={(e) => {
					if (e.target.value === 'false') {
						setcopy(false);
					} else if (e.target.value === 'true') {
						setcopy(true);
					}
				}}
			>
				<option value="false">False</option>
				<option value="true">True</option>
			</select>
			<label>Forceful:</label>
			<select
				className="leaderboard_options"
				onChange={(e) => {
					if (e.target.value === 'false') {
						setForceFul(false);
					} else if (e.target.value === 'true') {
						setForceFul(true);
					}
				}}
			>
				<option value="false">False</option>
				<option value="true">True</option>
			</select>
			<button className="leaderboard_btn" onClick={() => generateLinks(userId, assignmentCode, copy, forceFul)}>
				Submit
			</button>
		</div>
	);
};

export default GenerateLink;

import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const ConversationContentEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
		control,
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('ref_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefId"
					name="ref_id"
					defaultValue={queryResult?.data?.data.ref_id}
					autoFocus
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('ref_type', {
						required: 'Required',
					})}
					error={!!errors.ref_type}
					helperText={errors.ref_type?.message ? `${errors.ref_type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefType"
					name="ref_type"
					defaultValue={queryResult?.data?.data.ref_type}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('data_key', {
						required: 'Required',
					})}
					error={!!errors.data_key}
					helperText={errors.data_key?.message ? `${errors.data_key?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Datakey"
					name="data_key"
					defaultValue={queryResult?.data?.data.data_key}
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('data_value', {
						required: 'Required',
					})}
					error={!!errors.data_value}
					helperText={errors.data_value?.message ? `${errors.data_value?.message}` : undefined}
					margin="normal"
					fullWidth
					label="DataValue"
					name="data_value"
					defaultValue={queryResult?.data?.data.data_value}
					InputLabelProps={{ shrink: true }}
				/>
				<Controller
					control={control}
					name="lang"
					rules={{
						required: 'Required',
					}}
					defaultValue={queryResult?.data?.data.lang}
					render={({ field }) => (
						<Autocomplete
							options={['he', 'en']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Lang'}
									margin="normal"
									variant="outlined"
									error={!!errors.lang}
									helperText={errors.lang?.message ? `${errors.lang?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
			</Box>
		</Edit>
	);
};

import React from 'react';

import reportWebVitals from './reportWebVitals';
import App from './App';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<React.Suspense fallback="loading">
		<App />
	</React.Suspense>,
);

reportWebVitals();

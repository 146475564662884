import { IGasQuestion } from '@interfaces/index';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

export const SeedClassesList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
		},
		{
			field: 'seed_id',
			headerName: 'Seed ID',
			width: 150,
		},
		{
			field: 'user_id',
			headerName: 'User ID',
			width: 150,
		},
		{
			field: 'ref_id',
			headerName: 'Ref ID',
			minWidth: 150,
		},
		{
			field: 'ref_type',
			headerName: 'Ref Type',
			minWidth: 150,
		},
		// {
		// 	field: 'effective_at',
		// 	headerName: 'Effective at',
		// 	minWidth: 150,
		// },
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IGasQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

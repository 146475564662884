import axios from 'axios';
import { nanoid } from 'nanoid';
import { CONTENT_API_SERVER } from './../constants';

export const uploadFileCallback =
	({
		usecase,
		randomFileName,
		metadata,
		onSuccessCallback,
	}: {
		usecase: string;
		randomFileName?: boolean;
		metadata?: any;
		onSuccessCallback?: any;
	}) =>
	async ({ file, onError, onSuccess, onProgress }: any) => {
		try {
			let fileExt = file.type.includes('/') ? file.type.split('/')[1] : file.type;
			if (fileExt==='x-m4a') fileExt = 'm4a';
			const fileName = randomFileName ? `${nanoid()}_original.${fileExt}` : file.name;
			const response = await axios.post(CONTENT_API_SERVER + `/api/panel/operations/upload-url`, {
				usecase,
				file_name: fileName,
				metadata: metadata,
			});
			const returnData = response.data;
			const signedRequest = returnData.data;
			const options = {
				onUploadProgress: (event: ProgressEvent) => {
					const { loaded, total } = event;
					onProgress({ percent: Math.round((loaded / total) * 100) });
				},
				headers: {
					'Content-Type': file.type,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			};

			const requestUrl = signedRequest.split('?')[0];
			const result = await axios.put(signedRequest, file, options);

			onSuccess(result);
			file.url = requestUrl;
			if (onSuccessCallback) onSuccessCallback(file);
		} catch (err) {
			onError(err);
		}
	};

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const BookEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	return (
		<Edit
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					if (typeof values.classes === 'string') {
						const spls = values.classes
							.split(',')
							.filter((val) => val.trim().length > 0)
							.map((val) => String(val.trim()));
						setValue('classes', spls);
					}

					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.title}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					InputLabelProps={{ shrink: true }}
					name="title"
				/>
				<TextField
					{...register('display_name')}
					defaultValue={queryResult?.data?.data.display_name}
					error={!!errors.display_name}
					helperText={errors.display_name?.message ? `${errors.display_name?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Display Name"
					name="display_name"
				/>
				<TextField
					{...register('author')}
					defaultValue={queryResult?.data?.data.author}
					error={!!errors.author}
					helperText={errors.author?.message ? `${errors.author?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Author"
					name="author"
				/>
				<TextField
					{...register('book_type')}
					defaultValue={queryResult?.data?.data.book_type}
					error={!!errors.book_type}
					helperText={errors.book_type?.message ? `${errors.book_type?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Book Type"
					name="book_type"
				/>
				<TextField
					{...register('board_id', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.ref_order}
					error={!!errors.board_id}
					helperText={errors.board_id?.message ? `${errors.board_id?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Board Id"
					name="board_id"
					type="number"
				/>
				<Controller
					control={control}
					name="class_code"
					rules={{
						required: 'Required',
					}}
					defaultValue={queryResult?.data?.data.class_code}
					render={({ field }) => (
						<Autocomplete
							options={['3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Class'}
									margin="normal"
									variant="outlined"
									error={!!errors.class_code}
									helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('is_default')} defaultChecked={queryResult?.data?.data?.is_default} />}
						label="Is Default?"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('is_super_default')} defaultChecked={queryResult?.data?.data?.is_super_default} />}
						label="Is Super Default?"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

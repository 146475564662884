import React from 'react';
import { useDataGrid, List } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export const ContentFlagList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'entity_id',
			headerName: 'EntityId',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.entity_id + '';
			},
		},
		{
			field: 'entity_type',
			headerName: 'EntityType',
			width: 100,
		},
		{
			field: 'flag_value',
			headerName: 'FlagValue',
			width: 600,
		},
		{
			field: 'tag',
			headerName: 'Tag',
			minWidth: 150,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

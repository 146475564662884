import { uploadFileCallback } from '@helpers/content_helper';
import useRecorder, { EMPTY_AUDIO_URL, RECORDING } from '@hooks/useRecorder';
import { Button } from '@mui/material';
import { secondsToHMS } from '@utils/common';
import { Progress } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactWebCam from 'react-webcam';

enum UploadState {
	IDLE,
	RECORDING,
	UPLOADING,
	SUCCESS,
	ERROR,
}

interface Props {
	url: string;
	step?: string;
	questionId: number;
	onUpload?: (value: string) => void;
}

const VideoRecorder: FC<Props> = (props) => {
	const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
	const [progress, setProgress] = useState(0);
	const [uploadState, setUploadState] = useState(UploadState.IDLE);
	const [showSwitchCamera] = useState(true);

	const [
		uploadFile,
		recorderState,
		secondsRecording,
		recorderError,
		startRecording,
		pauseRecording,
		resumeRecording,
		destroyRecording,
		captureVideo,
	] = useRecorder('video', facingMode);

	const isRecording = useMemo(() => recorderState === RECORDING, [recorderState]);

	const handlePauseClick = () => {
		if (secondsRecording === 0) {
			startVideoRecording();
			return;
		}
		if (isRecording) {
			pauseRecording();
		} else {
			resumeRecording();
		}
	};

	const startVideoRecording = () => {
		startRecording(facingMode);
		setUploadState(UploadState.RECORDING);
	};

	const uploadVideoFile = () => {
		setUploadState(UploadState.UPLOADING);
		const file = new File([uploadFile], 'video', { type: 'mp4' });
		uploadFileCallback({
			usecase: 'medias',
			randomFileName: true,
			metadata: {
				step: props.step,
				question_id: String(props.questionId),
			},
			onSuccessCallback: ({ url }) => {
				props.onUpload(url);
				destroyRecording();
			},
		})({
			file: file,
			onError: () => setUploadState(UploadState.ERROR),
			onSuccess: () => setUploadState(UploadState.SUCCESS),
			onProgress: ({ percent }) => setProgress(percent),
		});
	};

	useEffect(() => {
		if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
			navigator.mediaDevices.enumerateDevices().then((devices) => {
					let videoDevices = 0;
					for (let index = 0; index <= devices.length; index++) if (devices[index]?.kind === 'videoinput') videoDevices++;
					// setShowSwitchCamera(videoDevices > 1);
			})
			.catch((err) => console.log(err));
		}
		return () => {
			destroyRecording();
		};
	}, []);

	useEffect(() => {
		if (uploadFile !== EMPTY_AUDIO_URL) {
			uploadVideoFile();
		}
	}, [uploadFile]);

	if (props.url) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(0,0,0,0.1)', borderRadius: '12px', padding: '16px' }}>
				<video src={props.url} controls style={{ height: '100%', width: '100%' }} />
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(0,0,0,0.1)', borderRadius: '12px', padding: '16px' }}>
			{recorderError ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>Some Error Occured (Give Permissions!)</div>
					<Button variant="contained" onClick={startVideoRecording}>
						Retry
					</Button>
				</div>
			) : uploadState === UploadState.UPLOADING ? (
				<Progress type="circle" percent={progress} size={100} />
			) : uploadState === UploadState.ERROR ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>Some Error Occured While Uploading!</div>
					<Button variant="contained" onClick={uploadVideoFile}>
						Retry
					</Button>
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<ReactWebCam
						audio={false}
						style={{ height: '100%', width: '100%', transform: facingMode === 'user' ? 'scaleX(-1)' : null }}
						videoConstraints={{ facingMode }}
					/>
					{uploadState === UploadState.IDLE ? (
						<>
							<div style={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}>You are ready to start recording</div>
							{showSwitchCamera && (
								<Button variant="contained" onClick={() => setFacingMode(facingMode === 'user' ? 'environment' : 'user')}>
									Switch Camrea
								</Button>
							)}
							<Button variant="contained" onClick={startVideoRecording}>
								Start Recording
							</Button>
						</>
					) : (
						<>
							<div style={{ fontSize: '20px', fontWeight: '600' }}>{secondsToHMS(Number(secondsRecording))}</div>
							<div style={{ fontSize: '20px', fontWeight: '500' }}>{isRecording ? 'You are being recorded' : 'Paused'}</div>
							{showSwitchCamera && (
								<Button variant="contained" onClick={() => setFacingMode(facingMode === 'user' ? 'environment' : 'user')}>
									Switch Camrea
								</Button>
							)}
							<div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
								<Button variant="outlined" onClick={handlePauseClick}>
									{isRecording ? 'Pause' : secondsRecording === 0 ? 'Record' : 'Resume'}
								</Button>
								<Button variant="contained" onClick={captureVideo}>
									Upload
								</Button>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default VideoRecorder;

export const getSupportedMimeTypes = (...mediaTypes) => {
	if (!mediaTypes.length) mediaTypes.push(...['video', 'audio']);
	const FILE_EXTENSIONS = ['mp3', 'mp4', 'webm', 'ogg', 'x-matroska'];
	const CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus'];

	const supportedMimeType = [
		...new Set(
			FILE_EXTENSIONS.flatMap((ext) =>
				CODECS.flatMap((codec) =>
					mediaTypes.flatMap((mediaType) => [
						`${mediaType}/${ext};codecs:${codec}`,
						`${mediaType}/${ext};codecs=${codec}`,
						`${mediaType}/${ext};codecs:${codec.toUpperCase()}`,
						`${mediaType}/${ext};codecs=${codec.toUpperCase()}`,
						`${mediaType}/${ext}`,
					]),
				),
			),
		),
	].filter((variation) => MediaRecorder.isTypeSupported(variation));
	const supportedExtension = supportedMimeType[0].split('/')[1].split(';')[0];
	return [supportedMimeType[0], supportedExtension];
};

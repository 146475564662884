import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const SeedEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('content', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.content}
					error={!!errors.content}
					helperText={errors.content?.message ? `${errors.content?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Content"
					name="content"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('url', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.url}
					error={!!errors.url}
					helperText={errors.url?.message ? `${errors.url?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Url"
					name="url"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('tags', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.tags}
					error={!!errors.tags}
					helperText={errors.tags?.message ? `${errors.tags?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Tags"
					name="tags"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('info', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.info}
					error={!!errors.info}
					helperText={errors.info?.message ? `${errors.info?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					name="info"
					InputLabelProps={{ shrink: true }}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('enabled')} defaultChecked={queryResult?.data?.data?.enabled} />}
						label="Enabled"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

import ToastHelper from '@helpers/toast_helper';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { MAIN_API_SERVER, TEMPLATE_NAME } from '../../constants';
import '../../styles/main.css';
import { isEmpty } from 'lodash';

const WhatsAppBotV2: FC = () => {
	const [userId, setUserId] = useState<any>('');
	const [phoneNumbers, setPhoneNumbers] = useState<any>('');
	const [variableKeys, setVariableKeys] = useState<string[]>([]);
	const [variableKeyValues, setVariableKeyValues] = useState<any[]>([]);
	const [buttonKeys, setButtonKeys] = useState<string[]>([]);
	const [buttonKeyValues, setButtonKeyValues] = useState<any[]>([]);
	const [variableHeader, setVariableHeader] = useState<string>('');
	const [staticHeader, setStaticHeader] = useState<string>();
	const [staticHeaderType, setStaticHeaderType] = useState<string>();
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [loading, setLoading] = useState(false);
	const [availableTemplates, setAvaailableTemplates] = useState(TEMPLATE_NAME);
	const [debug, setDebug] = useState(false);
	const [useHeader, setUseHeader] = useState(false);

	const url = `${MAIN_API_SERVER}/api/operations/whatsapp/sendV2`;
	const template_url = `${MAIN_API_SERVER}/api/operations/whatsapp/info?template_name=`;
	const templates_list_url = `${MAIN_API_SERVER}/api/operations/whatsapp/templates`;

	const handleSubmit = () => {
		if ((userId || phoneNumbers) && selectedTemplate) {
			setLoading(true);
			const Body: any = {
				user_ids: userId,
				phone_numbers: phoneNumbers,
				template_name: selectedTemplate,
				variables: phoneNumbers ? (!isEmpty(variableKeys) ? variableKeyValues : []) : variableKeys,
				buttons: !isEmpty(buttonKeys) ? (!isEmpty(buttonKeyValues) ? buttonKeyValues : buttonKeys) : [],
				debug: debug,
			};
			if (!useHeader && variableHeader?.length > 0) {
				Body['header'] = variableHeader;
			}
			if (useHeader && staticHeader && staticHeaderType) {
				Body['header'] = staticHeader;
				Body['header_type'] = staticHeaderType;
			}
			axios
				.post(url, Body)
				.then(() => {
					new ToastHelper().showInfo('Successful');
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					new ToastHelper().showError('Oops! Error in submitting. Please try again after sometime.');
				});
		}
	};

	const generateVariableKeys = (templateName: any) => {
		const finalUrl = template_url + templateName;
		axios
			.get(finalUrl)
			.then((response) => {
				setLoading(false);
				new ToastHelper().showInfo('Successful');
				setVariableKeys(response?.data?.data?.variable_keys);
				setVariableHeader(response?.data?.data?.header);
				setStaticHeader(response?.data?.data?.variable_keys);
				setStaticHeaderType(response?.data?.data?.rawExtras?.[0]?.components?.[0]?.format);
				setButtonKeys(response?.data?.data?.buttons);
			})
			.catch(() => {
				setLoading(false);
				new ToastHelper().showError('Oops! Error in generating variable keys right now. Please try again after sometime.');
			});
	};

	const getTemplateName = (templateName: string) => {
		if (templateName !== '') {
			setLoading(true);
			setSelectedTemplate(templateName);
			generateVariableKeys(templateName);
		}
	};

	useEffect(() => {
		axios
			.get(templates_list_url)
			.then((response) => {
				setLoading(false);
				setAvaailableTemplates(response?.data?.data?.templates ?? TEMPLATE_NAME);
			})
			.catch((e: any) => {
				setLoading(false);
				console.log(e);
				new ToastHelper().showError('Oops! Error in generating variable keys right now. Please try again after sometime.');
			});
	}, []);

	return (
		<div className="input_leaderboard">
			<TextField
				fullWidth
				type="text"
				label="User Id"
				margin="normal"
				onKeyDown={(e) => {
					if (variableKeys.length !== 0 && e.key === ',') e.preventDefault();
				}}
				onChange={(e) => setUserId(e.target.value)}
			/>
			<TextField fullWidth type="text" label="PhoneNumbers" margin="normal" onChange={(e) => setPhoneNumbers(e.target.value)} />
			<Autocomplete
				options={availableTemplates}
				value={selectedTemplate}
				fullWidth
				renderInput={(params) => <TextField {...params} label="Template" margin="normal" variant="outlined" required />}
				onChange={(_, value) => getTemplateName(value)}
			/>
			<TextField fullWidth type="text" label="Header URL, if using static" margin="normal" onChange={(e) => setStaticHeader(e.target.value)} />
			{variableKeys.map((vKey, index) => (
				<TextField
					key={index}
					fullWidth
					type={vKey === 'day' ? 'date' : vKey === 'time' ? 'time' : 'text'}
					label={vKey}
					margin="normal"
					onChange={(e) => {
						const values = [...variableKeyValues];
						values[index] = e.target.value;
						setVariableKeyValues(values);
					}}
				/>
			))}
			{buttonKeys.map((vKey, index) => (
				<TextField
					key={index}
					fullWidth
					type={vKey === 'day' ? 'date' : vKey === 'time' ? 'time' : 'text'}
					label={vKey}
					margin="normal"
					onChange={(e) => {
						const values = [...buttonKeyValues];
						values[index] = e.target.value;
						setButtonKeyValues(values);
					}}
				/>
			))}
			<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
				<button disabled={loading} className={loading ? 'grey_btn' : 'leaderboard_btn'} onClick={handleSubmit}>
					Submit
				</button>
				<Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginLeft={'5vw'}>
					Debug Mode
					<Checkbox title="Debug Mode" checked={debug} onClick={() => setDebug(!debug)} />
				</Box>
				<Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginLeft={'5vw'}>
					Use Example Header?
					<Checkbox title="Static Header" checked={useHeader} onClick={() => setUseHeader(!useHeader)} />
				</Box>
			</Box>
			{loading && <div className="loading">Loading...</div>}
		</div>
	);
};

export default WhatsAppBotV2;

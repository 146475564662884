import { FC, useState } from 'react';
import axios from 'axios';

import { Button, TextField } from '@mui/material';
import ToastHelper from '@helpers/toast_helper';
import { MAIN_API_SERVER } from '../../constants';

const CreatePaymentLink: FC = () => {
	const [userId, setUserId] = useState<number>();
	const [amount, setAmount] = useState<number>();
	const [link, setLink] = useState<string>();

	const createLink = async () => {
		if (!userId || !amount) {
			new ToastHelper().showError('Please fill all fields');
			return;
		}

		try {
			const res = await axios.post(`${MAIN_API_SERVER}/api/subscription/create/link`, {
				user_id: userId,
				price: amount,
				type: 'package',
			});
			setLink(res.data.data.url);
		} catch (error) {
			new ToastHelper().showError('Failed to create payment link');
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<div style={{ fontSize: '30px', fontWeight: 'bold', color: '#000', marginBottom: '30px' }}>Create Payment Link</div>

			<TextField label="User ID" required type="number" value={userId} onChange={(e) => setUserId(Number(e.target.value))} />
			<TextField
				style={{ marginTop: '20px' }}
				required
				label="Amount"
				type="number"
				value={amount}
				onChange={(e) => setAmount(Number(e.target.value))}
			/>
			<Button variant="contained" style={{ marginTop: '20px' }} onClick={createLink}>
				Create Link
			</Button>

			{link && (
				<>
					<div style={{ marginTop: '40px' }}>Payment link:</div>
					<div>{link}</div>
				</>
			)}
		</div>
	);
};

export default CreatePaymentLink;

import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const UserClassesList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			filterable: true,
			sortable: true,
		},
		{
			field: 'user_id',
			headerName: 'UserId',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'institute',
			headerName: 'institute',
			width: 100,
			filterable: true,
			sortable: true,
		},
		{
			field: 'institute_id',
			headerName: 'InstituteId',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'class_code',
			headerName: 'ClassCode',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'relation_type',
			headerName: 'RelationType',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'affiliate_group',
			headerName: 'AffiliateGroup',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'section',
			headerName: 'section',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'enabled',
			headerName: 'enabled',
			minWidth: 150,
			flex: 1,
			filterable: true,
			sortable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} />
		</List>
	);
};

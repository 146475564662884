import { toast } from 'react-toastify';

export default class ToastHelper {
	showError(msg: string) {
		toast.error(msg, {
			position: 'bottom-center',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	showInfo(msg: string, autoClose?: number, closeOnClick?: boolean) {
		toast.info(msg, {
			position: 'bottom-center',
			autoClose: autoClose || 2000,
			hideProgressBar: true,
			closeOnClick: closeOnClick === false ? closeOnClick : true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	showSuccess(msg: string) {
		toast.success(msg, {
			position: 'bottom-center',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

import { CONTENT_API_SERVER } from '../../constants';
import Box from '@mui/material/Box'
import { Typography, Upload } from 'antd';

export const QuestionImagesList: React.FC = () => {
	const { Title } = Typography;

	return (
		<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
			<Title level={5}>Question Image Upload</Title>
			<Upload.Dragger name="file" action={CONTENT_API_SERVER + `/api/panel/operations/questions/images`} maxCount={10} multiple={true}>
				<p className="ant-upload-text">Drag & drop a file in this area</p>
			</Upload.Dragger>
		</Box>
	);
};

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const InstituteClassesCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('institute_id', {
						required: 'Required',
					})}
					error={!!errors.institute_id}
					helperText={errors.institute_id?.message ? `${errors.institute_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Institute ID"
					name="institute_id"
					autoFocus
				/>
				<TextField
					{...register('class_code', {
						required: 'Required',
					})}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Class Code"
					name="class_code"
					autoFocus
				/>
				<TextField
					type="number"
					{...register('trial_period', {
						required: 'Required',
					})}
					error={!!errors.trial_period}
					helperText={errors.trial_period?.message ? `${errors.trial_period?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Trial Period"
					name="trial_period"
					autoFocus
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_active')} />} label="Is Active?" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_gas_on')} />} label="Is Gas On?" />
				</FormGroup>
			</Box>
		</Create>
	);
};

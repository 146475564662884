import { useState } from 'react';
import '../../styles/main.css';
import { MAIN_API_SERVER } from './../../constants';
import axios from 'axios';
import ToastHelper from '@helpers/toast_helper';

const LinkPreference = () => {
	const [inputVal, setInputValue] = useState<any>();
	const [selectedValue, setSelectedValue] = useState<any>();

	var url = `${MAIN_API_SERVER}/api/panel/user_preferences/bulk`;

	const handleSubmitBtn = () => {
		axios
			.post(url, { user_ids: inputVal, action: selectedValue })
			.then(function (response) {
				new ToastHelper().showInfo(response?.data?.data?.code || 'Successfull', 1000, false);
			})
			.catch((err: any) => {
				new ToastHelper().showError(err?.message || 'Oops! Error in submitting. Please try again after sometime.');
			});
	};

	const handleInputChange = (event: any) => {
		const inputValue = event.target.value;
		setInputValue(inputValue);
	};

	function handleSelectChange(event: any) {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue);
	}

	return (
		<div className="link_preference">
			<input onChange={handleInputChange} type="text" />
			<select onChange={handleSelectChange} className="link_preference_dropdown">
				<option value="link_on">Link On</option>
				<option value="link_off">Link Off</option>
			</select>
			<button
				disabled={!inputVal || !selectedValue}
				onClick={handleSubmitBtn}
				className={!inputVal || !selectedValue ? 'link_preference_disable' : ''}
			>
				Submit
			</button>
		</div>
	);
};

export default LinkPreference;

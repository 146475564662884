import { uploadFileCallback } from '@helpers/content_helper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Typography, Upload } from 'antd';
import { useState } from 'react';

export const EmbeddedItemEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	const [thumbnailUrl, setThumbnailUrl] = useState<string>(null);

	const onSuccessUpload = (file) => {
		setThumbnailUrl(file.url.split('?')[0]);
	};

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
					defaultValue={queryResult?.data?.data.title}
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('type')}
					error={!!errors.type}
					helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Type"
					defaultValue={queryResult?.data?.data.type}
					InputLabelProps={{ shrink: true }}
					name="type"
				/>
				<TextField
					{...register('url')}
					error={!!errors.url}
					helperText={errors.url?.message ? `${errors.url?.message}` : undefined}
					margin="normal"
					fullWidth
					defaultValue={queryResult?.data?.data.url}
					InputLabelProps={{ shrink: true }}
					label="Url"
					name="url"
				/>
				<TextField
					{...register('thumbnail')}
					error={!!errors.thumbnail}
					helperText={errors.thumbnail?.message ? `${errors.thumbnail?.message}` : undefined}
					margin="normal"
					fullWidth
					defaultValue={thumbnailUrl || queryResult?.data?.data.thumbnail}
					value={thumbnailUrl}
					InputLabelProps={{ shrink: true }}
					label="Thumbnail"
					name="thumbnail"
				/> 
				<Typography.Title level={5}>Thumbnail Upload</Typography.Title>
				<Upload.Dragger name="file" customRequest={uploadFileCallback({ usecase: 'thumbnails', onSuccessCallback: onSuccessUpload, randomFileName: true })} listType="picture">
					<p className="ant-upload-text">Drag & drop a file in this area</p>
				</Upload.Dragger>
			</Box>
		</Edit>
	);
};

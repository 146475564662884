import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ariselogo from '../../assets/Group 31.png';
import '../../styles/main.css';
import APIHelper from '@helpers/api_helper';
import ToastHelper from '@helpers/toast_helper';

const AssignmentLinkPage = () => {
	const [student, setStudent] = useState([]);
	const [imgsrc, setimgsrc] = useState('');
	const [date, setdate] = useState();
	const { institute, section, target_date } = useParams();

	useEffect(() => {
		fetchStudents(institute, section, target_date);
	}, []);

	const fetchStudents = (institute: any, section: any, target_date: any) => {
		new ToastHelper().showInfo('Loading links');
		new APIHelper()
			.fetchAssignmentLinks(institute, section, target_date)
			.then((data: any) => {
				setStudent(data.sections[0].records as any);
				setimgsrc(data.institute_logo as any);
				setdate(target_date);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of students right now. Please try again after sometime.');
			});
	};

	return (
		<div className="bdy">
			<div className="main">
				<div className="header">
					<img src={imgsrc} alt="" />
					<img src={ariselogo} alt="" />
				</div>
				<div className="date_info">
					<b>{date}</b>
				</div>
				<div className="student_info">
					<div className="student_list">
						<p>Student</p>
						<b>Links</b>
					</div>
					{student.map((el: any) => (
						<div className="student_list">
							<p>{el?.student_name}</p>
							<b>{el?.link}</b>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AssignmentLinkPage;

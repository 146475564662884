import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const PlanEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.name}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.title}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('duration', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.duration}
					error={!!errors.duration}
					helperText={errors.duration?.message ? `${errors.duration?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Duration"
					name="duration"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('base_price', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.base_price}
					error={!!errors.base_price}
					helperText={errors.base_price?.message ? `${errors.base_price?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Base Price"
					name="base_price"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('discounted_price', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.discounted_price}
					error={!!errors.discounted_price}
					helperText={errors.discounted_price?.message ? `${errors.discounted_price?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Discounted Price"
					name="discounted_price"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('start_date', {
						required: 'Required',
						valueAsDate: true,
					})}
					defaultValue={queryResult?.data?.data?.start_date}
					error={!!errors.start_date}
					helperText={errors.start_date?.message ? `${errors.start_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="StartDate"
					name="start_date"
					type="date"
					placeholder=""
					InputLabelProps={{ shrink: true }}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('enabled')} defaultChecked={queryResult?.data?.data?.enabled} />}
						label="Enabled"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const InstituteCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
					autoFocus
				/>
				<TextField
					{...register('state')}
					error={!!errors.state}
					helperText={errors.state?.message ? `${errors.state?.message}` : undefined}
					margin="normal"
					fullWidth
					label="State"
					name="state"
				/>
				<TextField
					{...register('type')}
					error={!!errors.type}
					helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Type"
					name="type"
				/>
				<TextField
					{...register('trial_period')}
					type="number"
					error={!!errors.trial_period}
					helperText={errors.trial_period?.message ? `${errors.trial_period?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Trial Period"
					name="trial_period"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('enabled')} />} label="Enabled" />
				</FormGroup>
			</Box>
		</Create>
	);
};

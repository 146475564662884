import { useEffect, useState } from 'react';
import { CONTENT_API_SERVER } from './../constants';
import axios from 'axios';

const useSeriesData = () => {
	const [seriesList, setSeriesList] = useState<any>([]);

	useEffect(() => {
		const res_url = `${CONTENT_API_SERVER}/api/panel/series`;
		axios
			.get(res_url)
			.then(function (res) {
				setSeriesList(res?.data?.data);
			})
			.catch(() => {
				console.log('error');
			});
	}, []);

	return seriesList;
};

export default useSeriesData;

import { AuthBindings } from '@refinedev/core';
import useContentStore from '@stores/content';

export const TOKEN_KEY = 'refine-auth';

export const authProvider: AuthBindings = {
	login: async (params: any) => {
		console.log(params);
		const { username, password } = params;
		if (username === 'admin' && password === 'otherpass') {
			localStorage.setItem(TOKEN_KEY, username);
			setTimeout(() => {
				window.location.reload();
			}, 500);
			return { success: true, redirectTo: '/' };
		} else if (username === 'admin2' && password === 'adminpass') {
			localStorage.setItem(TOKEN_KEY, username);
			setTimeout(() => {
				window.location.reload();
			}, 500);
			return { success: true, redirectTo: '/' };
		} else if (username === 'contentteam' && password === 'network90') {
			localStorage.setItem(TOKEN_KEY, username);
			setTimeout(() => {
				window.location.reload();
			}, 500);
			return { success: true, redirectTo: '/' };
		} else if (username === 'agent' && password === 'missionpossible') {
			localStorage.setItem(TOKEN_KEY, username);
			setTimeout(() => {
				window.location.reload();
			}, 500);
			return { success: true, redirectTo: '/' };
		}
		return { success: false, error: new Error('Not able valid creds') };
	},
	onError: async (error: any) => {
		return { error };
	},
	logout: async () => {
		localStorage.removeItem(TOKEN_KEY);
		useContentStore.getState().reset();
		setTimeout(() => {
			window.location.reload();
		}, 500);
		return { success: true, redirectTo: '/login' };
	},
	check: async () => {
		const token = localStorage.getItem(TOKEN_KEY);
		if (!token) {
			return {
				authenticated: false,
				logout: true,
				error: new Error('No token found'),
				redirectTo: '/login',
			};
		}
		return { authenticated: true };
	},
	getIdentity: async () => {
		const token = localStorage.getItem(TOKEN_KEY);
		if (!token) {
			return null;
		}

		return { id: 1 };
	},
};

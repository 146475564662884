import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const FeatureFlagEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('ref_id')}
					defaultValue={queryResult?.data?.data?.ref_id}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefId"
					name="ref_id"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('ref_type')}
					defaultValue={queryResult?.data?.data?.ref_type}
					error={!!errors.ref_type}
					helperText={errors.ref_type?.message ? `${errors.ref_type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefType"
					name="ref_type"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('flag_name')}
					defaultValue={queryResult?.data?.data?.flag_name}
					error={!!errors.flag_name}
					helperText={errors.flag_name?.message ? `${errors.flag_name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="FlagName"
					name="flag_name"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('flag_value')}
					defaultValue={queryResult?.data?.data?.flag_value}
					error={!!errors.flag_value}
					helperText={errors.flag_value?.message ? `${errors.flag_value?.message}` : undefined}
					margin="normal"
					fullWidth
					label="FlagValue"
					name="flag_value"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('flag_config')}
					defaultValue={queryResult?.data?.data?.flag_config}
					error={!!errors.flag_config}
					helperText={errors.flag_config?.message ? `${errors.flag_config?.message}` : undefined}
					margin="normal"
					fullWidth
					label="FlagConfig"
					name="flag_config"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('priority')}
					defaultValue={queryResult?.data?.data?.priority}
					error={!!errors.assignment_code}
					helperText={errors.priority?.message ? `${errors.priority?.message}` : undefined}
					margin="normal"
					fullWidth
					label="priority"
					name="priority"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
			</Box>
		</Edit>
	);
};

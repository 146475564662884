import React, { useState } from 'react';
import Select from 'react-select';
import { QuestionItem } from './QuestionItem';
import APIHelper from '../helpers/api_helper';
import ToastHelper from '../helpers/toast_helper';
import classnames from 'classnames';
import MathContent from './MathContent';

type QuestionItemFormProps = {
	input: {
		id: string;
		title: string;
		descp: string;
		options: any;
		status: any;
		image_required: any;
		difficult_lvl: any;
		expected_duration: any;
		section_descp: string;
		section_title: string;
		base_code: string;
		type: string;
		images: any[];
		key_concepts: any[];
		other_concepts: any[];
	};
	index: number;
};

export const QuestionItemForm: React.FC<QuestionItemFormProps> = ({ input, index }) => {
	const [fields, setFields] = useState({});
	const [options, setOptions] = useState([]);
	const questionId = input.id;
	const dflt_options = input.options;

	const setValues = (name: string) => {
		return ({ target: { value, checked } }: any) => {
			(fields as any)[name] = value === 'on' ? checked : value;
			setFields(fields);
		};
	};

	const handleInputChange = (inputValue: any) => {
		let toastHelper = new ToastHelper();

		if (inputValue.length >= 3 && inputValue.trim() !== '') {
			new APIHelper()
				.fetchSearchedConcepts(inputValue.trim())
				.then((data) => {
					let optionsArr = data.data.map(({ id, title }: any) => ({ value: id, label: title }));
					setOptions(optionsArr);
				})
				.catch((e) => {
					toastHelper.showError(`Error in updating section - ${e.message}`);
				});
		}
	};

	const handleSubmit = () => {
		let options: any[] = [];

		// other concepts
		let other_concepts: any[] = [];
		for (let i = 0; document.getElementsByName(`other_concepts-${questionId}[]`).length > i; i++) {
			other_concepts.push((document.getElementsByName(`other_concepts-${questionId}[]`)[i] as any).value);
		}

		// key concepts
		let key_concepts = [];
		for (let i = 0; document.getElementsByName(`key_concepts-${questionId}[]`).length > i; i++) {
			key_concepts.push((document.getElementsByName(`key_concepts-${questionId}[]`)[i] as any).value);
		}

		for (let i = 0; i < 4; i++) {
			let option: any = {};
			if ((fields as any)[`option-${i}-value`] !== undefined) {
				option['descp'] = (fields as any)[`option-${i}-value`];
				if (dflt_options[i] && dflt_options[i].id) {
					option['id'] = dflt_options[i].id;
				}
			} else if (dflt_options && dflt_options[i] && dflt_options[i].descp) {
				option['id'] = dflt_options[i].id;
				option['descp'] = dflt_options[i].descp;
			}
			if ((fields as any)[`option-${i}-correct`] !== undefined) {
				option['is_correct'] = (fields as any)[`option-${i}-correct`];
			} else if (dflt_options && dflt_options[i] && dflt_options[i].is_correct) {
				option['is_correct'] = dflt_options[i].is_correct;
			}
			if ((fields as any)[`option-${i}-concepts`] !== undefined) {
				option['concepts'] = (fields as any)[`option-${i}-concepts`];
			}
			if (Object.keys(option).length > 0) {
				option['ref_order'] = i + 1;
				options.push(option);
			}
		}
		let data: any = {};
		data['options'] = options;
		if ((fields as any)['lvl']) {
			data['difficult_lvl'] = (fields as any)['lvl'];
		}
		if ((fields as any)['avg_time']) {
			data['expected_duration'] = (fields as any)['avg_time'];
		}
		if (key_concepts.length > 0) {
			data['key_concepts'] = key_concepts;
		}
		if (other_concepts.length > 0) {
			data['other_concepts'] = other_concepts;
		}
		if ((fields as any)['status']) {
			data['status'] = (fields as any)['status'];
		}
		let toastHelper = new ToastHelper();
		toastHelper.showInfo('Updating Question');
		new APIHelper()
			.updateQuestion(questionId, data)
			.then(() => {
				toastHelper.showSuccess('Updated Question');
			})
			.catch((e) => {
				toastHelper.showError(`Error in updating question - ${e.message}`);
			});
	};

	if (!input.options) input.options = [];
	let ln = input.options.length;
	if (ln < 4) {
		for (let i = 0; i < 4 - ln; i++) {
			input.options.push({ descp: '' });
		}
	}
	return (
		<tr>
			<td>
				<span className={classnames('label', input.status === 'hold' ? 'red-row' : '')}></span>
				<span className={classnames('label', input.image_required ? 'yellow-row' : '')}></span>
			</td>
			<td>{input.id}</td>
			<td>{input.base_code}</td>
			<td className={'tbl-title'}>
				{input.section_descp} {input.section_title}
			</td>
			<td>{index + 1}</td>
			<td className={'tbl-descp'}>
				<QuestionItem content={input.descp} images={input.images} />
			</td>
			<td>{input.type}</td>
			{input.options.map((e: any, i: any) => {
				return (
					<td key={i}>
						Value: <MathContent content={e.descp} />
						<input defaultValue={e.descp || ''} name={`option-${i}-value`} onChange={setValues(`option-${i}-value`)} />
						<br />
						IsCorrect:{' '}
						<input
							type="checkbox"
							name={`option-${i}-correct`}
							onChange={setValues(`option-${i}-correct`)}
							defaultChecked={e.is_correct}
						/>
						<br />
						Concepts: <input name={`option-${i}-concepts`} onChange={setValues(`option-${i}-concepts`)} />
					</td>
				);
			})}
			<td>
				<input name="lvl" onChange={setValues(`lvl`)} defaultValue={input.difficult_lvl || ''} />
			</td>
			<td>
				<input name="avg_time" onChange={setValues(`avg_time`)} defaultValue={input.expected_duration || ''} />
			</td>

			<td className="tbl-descp">
				<Select
					options={options}
					onInputChange={handleInputChange}
					defaultValue={input.key_concepts}
					isClearable={false}
					className="basic-multi-select"
					classNamePrefix="select"
					isMulti
					name={`key_concepts-${input.id}[]`}
				/>
			</td>
			<td className="tbl-descp">
				<Select
					options={options}
					onInputChange={handleInputChange}
					defaultValue={input.other_concepts}
					isClearable={false}
					className="basic-multi-select"
					classNamePrefix="select"
					isMulti
					name={`other_concepts-${input.id}[]`}
				/>
			</td>
			<td>
				<select name="status" onChange={setValues(`status`)}>
					<option value="draft" selected={input.status === 'draft'}>
						Draft
					</option>
					<option value="reviewed" selected={input.status === 'reviewed'}>
						Reviewed
					</option>
					<option value="processed" selected={input.status === 'processed'}>
						Processed
					</option>
					<option value="hold" selected={input.status === 'hold'}>
						Hold
					</option>
					<option value="rejected" selected={input.status === 'rejected'}>
						Rejected
					</option>
				</select>
			</td>
			<td>
				<button onClick={handleSubmit}>Update</button>
			</td>
		</tr>
	);
};

import React from 'react';
import { useDataGrid, List, ListButton } from '@refinedev/mui';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IAssignment } from '@interfaces/index';

const columns: GridColDef<any>[] = [
	{
		field: 'id',
		headerName: 'Id',
		type: 'number',
		width: 150,
		renderCell: ({ row }: any) => {
			return row.id + '';
		},
	},
	{
		field: 'title',
		headerName: 'Title',
		minWidth: 150,
		flex: 1
	},
	{
		field: 'code',
		headerName: 'AssignmentCode',
		minWidth: 150,
		flex: 1
	},
	{
		field: 'info',
		headerName: 'AssignmentInfo',
		minWidth: 150,
		flex: 1
	},
	{
		field: 'type',
		headerName: 'AssignmentType',
		minWidth: 100,
		flex: 1,
	},
	{
		field: 'question_count',
		headerName: 'QuestionCount',
		minWidth: 100,
		flex: 1,
	},
	{
		field: 'series_ids',
		headerName: 'SeriesIds',
		minWidth: 100,
		flex: 1,
	},
	{
		field: 'classroom_id',
		headerName: 'ClassroomId',
		minWidth: 100,
		flex: 1,
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: 'Actions',
		renderCell: function render({ row }) {
			// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
			return (
				<>
					<ListButton hideText meta={{assignment_id: row.id}} />
				</>
			);
		},
		align: 'center',
		headerAlign: 'center',
		minWidth: 150,
	},
];

export const AssignmentList: React.FC = () => {

	const { dataGridProps } = useDataGrid<IAssignment>({
		pagination: {
			current: 1,
			pageSize: 25
		},
		sorters: {
			initial: [{ field: "id", order: "desc" }],
		},
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

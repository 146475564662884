import React from 'react';
import { useDataGrid, List } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const SimilarUsersList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'primary_user_id',
			headerName: 'PrimaryUserId',
			minWidth: 150,
			flex: 1,
			sortable: false,
			filterable: false,
		},
		{
			field: 'primary_user_info.full_name',
			headerName: 'P_UserName',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.primary_user_info ? row.primary_user_info.full_name + '' : '';
			},
		},
		{
			field: 'primary_user_info.enabled',
			headerName: 'P_UserEnabled',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.primary_user_info ? row.primary_user_info.enabled + '' : '';
			},
		},
		{
			field: 'secondary_user_id',
			headerName: 'SecondaryUserId',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'secondary_user_info.full_name',
			headerName: 'S_UserName',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.secondary_user_info ? row.secondary_user_info.full_name + '' : '';
			},
		},
		{
			field: 'secondary_user_info.enabled',
			headerName: 'S_UserEnabled',
			minWidth: 150,
			flex: 1,
			sortable: false,
			filterable: false,
			renderCell: ({ row }: any) => {
				return row.secondary_user_info ? row.secondary_user_info.enabled + '' : '';
			},
		},
		{
			field: 'state',
			headerName: 'State',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'migrated_timestamp',
			headerName: 'MigratedTimestamp',
			minWidth: 100,
			flex: 1,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { List } from '@refinedev/mui';
import { SectionItemForm } from '@components/SectionItemForm';
import ToastHelper from '@helpers/toast_helper';
import APIHelper from '@helpers/api_helper';

export const AllSectionPage: React.FC = () => {
	const [sections, setSections] = useState([]);
	const { grade, subject, book } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchSections(grade, subject, book);
	}, []);

	const fetchSections = (grade: any, subject: any, book: any) => {
		new ToastHelper().showInfo('Loading Sections');
		new APIHelper()
			.fetchSections(grade, subject, book)
			.then((data: any) => {
				setSections(Object.values(data).flat() as any);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of questions right now. Please try again after sometime.');
			});
	};

	return (
		<div data-component="AllSectionPage">
			<div className={'questions mtop-70'}>
				<div>
					<b>
						Class: {grade}, Subject: {subject}, BookName: {book}
					</b>
				</div>
				<List>
					<table>
						<thead>
							<tr>
								<td>ID</td>
								<td>Section</td>
								<td>Concepts</td>
							</tr>
						</thead>
						<tbody>
							{sections.map((e, i) => {
								return <SectionItemForm input={e} index={i} key={i} />;
							})}
						</tbody>
					</table>
				</List>
			</div>
		</div>
	);
};

import { Box, TextField } from '@mui/material';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const RefundEdit: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="User ID"
					name="user_id"
					autoFocus
				/>
				<TextField
					type="number"
					{...register('amount', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.amount}
					helperText={errors.amount?.message ? `${errors.amount?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Amount"
					name="amount"
					autoFocus
				/>

				<TextField
					{...register('processed_date', {
						required: 'Required',
					})}
					error={!!errors.processed_date}
					helperText={errors.processed_date?.message ? `${errors.processed_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Processed Date"
					name="processed_date"
					type="date"
				/>
				<TextField
					{...register('reason', {
						required: 'Required',
					})}
					error={!!errors.reason}
					helperText={errors.reason?.message ? `${errors.reason?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Reason"
					name="reason"
				/>
				<TextField
					{...register('category', {
						required: 'Required',
					})}
					error={!!errors.category}
					helperText={errors.category?.message ? `${errors.category?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Category"
					name="category"
					autoFocus
				/>
			</Box>
		</Edit>
	);
};

import { getSupportedMimeTypes } from '@utils/utility';
import { useEffect, useRef, useState } from 'react';

export const INACTIVE = 'inactive';
export const RECORDING = 'recording';
export const PAUSED = 'paused';
export const EMPTY_AUDIO_URL = '';

const useRecorder = (type: 'video' | 'audio', facingMode?: 'user' | 'environment') => {
	const recorderStream = useRef<MediaStream>(null);
	const recorderOriginalStream = useRef<MediaStream>(null);

	const [file, setFile] = useState<any>('');
	const [recorderState, setRecorderState] = useState(INACTIVE); // inactive, recording, paused
	const [recorder, setRecorder] = useState<MediaRecorder>(null);
	const [secondsRecording, setSecondsRecording] = useState(0);
	const [recorderError, setRecorderError] = useState(false);
	const [mimeTypeAndExtension, setMimeTypeAndExtension] = useState([]);

	const destroyTracks = () => {
		// broswer wont automattclly stop stream on stream.stop(), have to release every track manually
		// mostly it will be one track, can be multiple.
		if (recorderStream.current) {
			const tracks = recorderStream.current.getTracks();
			tracks.forEach((track) => track.stop());
		}

		if (recorderOriginalStream.current) {
			const tracks = recorderOriginalStream.current.getTracks();
			tracks.forEach((track) => track.stop());
		}

		if (recorder) recorder.stop();
		recorderOriginalStream.current = null;
		recorderStream.current = null;
		setRecorder(null);
	};

	const resetState = (withAudioFile = true) => {
		if (withAudioFile) setFile('');
		setRecorderState(INACTIVE);
		setSecondsRecording(0);
		destroyTracks();
		setRecorderError(false);
	};

	useEffect(() => {
		if (recorder === null) return;
		// Obtain the audio when ready.
		const handleAudioFile = (e: any) => {
			const blob = new Blob([e.data], { type: mimeTypeAndExtension[0] });
			setFile(blob);
			resetState(false); // without reseting audioFile
		};
		const handleRecorderError = () => {
			resetState(); // without reseting audioFile
			setRecorderError(true);
		};
		recorder.addEventListener('dataavailable', handleAudioFile);
		recorder.addEventListener('error', handleRecorderError);
		return () => {
			recorder.removeEventListener('dataavailable', handleAudioFile);
			recorder.removeEventListener('error', handleRecorderError);
		};
	}, [recorder]);

	useEffect(() => {
		// start timer when recording starts and pause when recording pauses
		if (recorderState === RECORDING) {
			const interval = setInterval(() => {
				setSecondsRecording((seconds) => seconds + 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [recorderState]);

	useEffect(() => {
		// resetState();
	}, [facingMode]);

	const getStream = async (facingMode: string) => {
		try {

			const videoConstraint = {
				width: { min: 640, ideal: 720, max: 720 },
    			height: { min: 400, ideal: 480 },
				frameRate: { max: 30 },
				facingMode
			}
			const audioConstraint = {
				sampleSize: 16,
				channelCount: 2,
				echoCancellation: true,
				noiseSuppression: true
			}
			return await navigator.mediaDevices.getUserMedia({ audio: audioConstraint, video: type==='video' ? videoConstraint : false });
		} catch (err) {
			console.log('err', err);
			return null;
		}
	}

	// exposed methods below
	const startRecording = async (facingMode?: 'user' | 'environment') => {
		console.log('recording', facingMode);
		const [supportedMimeType, supportedExtension] = getSupportedMimeTypes(type);
		const originalStream = await getStream(facingMode);
		if (!originalStream) {
			alert('Not able to record. Report to admin')
			return;
		}
		let stream: MediaStream;

		// if (type === 'video' && facingMode === 'user') {
		// 	const video = document.createElement('video');
		// 	video.srcObject = originalStream;
		// 	// video.muted = true;
		// 	video.play();

		// 	// Create a canvas and get its context
		// 	const canvas = document.createElement('canvas');
		// 	const context = canvas.getContext('2d');
		// 	canvas.width = video.videoWidth;
		// 	canvas.height = video.videoHeight;

		// 	// Mirror the video by drawing it on the canvas
		// 	video.addEventListener('loadedmetadata', () => {
		// 		canvas.width = video.videoWidth;
		// 		canvas.height = video.videoHeight;

		// 		// Continuously draw the video onto the canvas, mirrored
		// 		function drawVideo() {
		// 			context.save();
		// 			context.scale(-1, 1); // Flip the context horizontally
		// 			context.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);
		// 			context.restore();
		// 			requestAnimationFrame(drawVideo);
		// 		}
		// 		drawVideo();
		// 	});

		// 	// Capture the canvas as a stream
		// 	stream = canvas.captureStream();

		// 	// Add the audio tracks from the original stream to the mirrored stream
		// 	const audioTracks = originalStream.getAudioTracks();
		// 	audioTracks.forEach((track) => stream.addTrack(track));
		// } else {
		// 	stream = originalStream;
		// }

		stream = originalStream;

		const mediaRecorder = new MediaRecorder(stream, {
			mimeType: supportedMimeType,
		});

		recorderStream.current = stream;
		recorderOriginalStream.current = originalStream;

		setRecorder(mediaRecorder);
		mediaRecorder.start();
		setRecorderState(RECORDING);
		setMimeTypeAndExtension([supportedExtension, supportedMimeType]);
	};

	const pauseRecording = () => {
		if (recorder) recorder.pause();
		setRecorderState(PAUSED);
	};

	const resumeRecording = () => {
		if (recorder) recorder.resume();
		setRecorderState(RECORDING);
	};

	const destroyRecording = () => {
		resetState();
	};

	const captureAudio = () => {
		if (recorder?.state === INACTIVE) return;
		if (recorder) recorder.stop();
	};

	return [
		file,
		recorderState,
		secondsRecording,
		// mimeTypeAndExtension,
		recorderError,
		startRecording,
		pauseRecording,
		resumeRecording,
		destroyRecording,
		captureAudio,
	];
};

export default useRecorder;

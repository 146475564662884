import ToastHelper from '@helpers/toast_helper';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import Axios from 'axios';
import { MAIN_API_SERVER } from '../../constants';

const URL = `${MAIN_API_SERVER}/api/panel/social/seed_classes`;

export const SeedClassesCreate: React.FC = () => {
	const {
		// saveButtonProps,
		register,
		getValues,
		formState: { errors },
	} = useForm();

	const createSeedClasses = () => {
		const values = getValues();

		Axios.post(URL, {
			seed_id: Number(values.seed_id),
			user_id: Number(values.user_id),
			institute_class_id: String(values.institute_class_id),
			institute_id: String(values.institute_id),
			class_code: String(values.class_code),
		})
			.then(() => new ToastHelper().showSuccess('Successfully created'))
			.catch(() => new ToastHelper().showError('Something went wrong!'));
	};

	return (
		<Create saveButtonProps={{ onClick: createSeedClasses }}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('seed_id', {
						required: 'Required',
					})}
					error={!!errors.seed_id}
					helperText={errors.seed_id?.message ? `${errors.seed_id?.message}` : undefined}
					margin="normal"
					fullWidth
					type="number"
					label="Seed ID"
					name="seed_id"
					autoFocus
				/>
				<TextField
					{...register('user_id')}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					type="number"
					label="User ID"
					name="user_id"
				/>
				<TextField
					{...register('institute_class_id')}
					error={!!errors.institute_class_id}
					helperText={errors.institute_class_id?.message ? `${errors.institute_class_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Institute Class ID"
					name="institute_class_id"
				/>
				<TextField
					{...register('institute_id')}
					error={!!errors.institute_id}
					helperText={errors.institute_id?.message ? `${errors.institute_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Institute ID"
					name="institute_id"
				/>
				<TextField
					{...register('class_code')}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Class Code"
					name="class_code"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('enabled')} defaultChecked={true} />} label="Enabled" />
				</FormGroup>
			</Box>
		</Create>
	);
};

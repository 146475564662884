import { Button } from '@mui/material';
import { TitleProps, useRouterContext } from '@refinedev/core';
import React from 'react';

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
	const { Link } = useRouterContext();

	return (
		<Button fullWidth variant="text" disableRipple>
			<Link to="/" href="/">
				{collapsed ? <img src="/logo_collapsed.png" alt="Refine" width="28px" /> : <img src="/logo.webp" alt="Refine" width="140px" />}
			</Link>
		</Button>
	);
};

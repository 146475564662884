import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const EmbeddedItemCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
					autoFocus
				/>
				<TextField
					{...register('type')}
					error={!!errors.type}
					helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Type"
					name="type"
				/>
				<TextField
					{...register('url')}
					error={!!errors.url}
					helperText={errors.url?.message ? `${errors.url?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Url"
					name="url"
				/>
				<TextField
					{...register('thumbnail')}
					error={!!errors.thumbnail}
					helperText={errors.thumbnail?.message ? `${errors.thumbnail?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Thumbnail"
					name="thumbnail"
				/>
			</Box>
		</Create>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const ProgramSeriesCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('program_name', {
						required: 'Required',
					})}
					error={!!errors.program_name}
					helperText={errors.program_name?.message ? `${errors.program_name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Program Name"
					name="program_name"
					autoFocus
				/>
				<TextField
					{...register('subject_code', {
						required: 'Required',
					})}
					error={!!errors.subject_code}
					helperText={errors.subject_code?.message ? `${errors.subject_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Subject Code"
					name="subject_code"
				/>
				<TextField
					{...register('session', {
						required: 'Required',
					})}
					error={!!errors.session}
					helperText={errors.session?.message ? `${errors.session?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Session"
					name="session"
				/>
				<TextField
					{...register('class_code', {
						required: 'Required',
					})}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Class Code"
					name="class_code"
				/>

				<TextField
					{...register('series_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.series_id}
					helperText={errors.series_id?.message ? `${errors.series_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Series Id"
					name="series_id"
				/>
				<TextField
					{...register('ref_order', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Ref Order"
					name="ref_order"
					type="number"
				/>
			</Box>
		</Create>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Create } from '@refinedev/mui';

import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const ConceptCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		control,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
					multiline
					rows={4}
					autoFocus
				/>
				<TextField
					{...register('descp', {
						required: 'Required',
					})}
					error={!!errors.descp}
					helperText={errors.descp?.message ? `${errors.descp?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Descp"
					name="descp"
					multiline
					rows={4}
					autoFocus
				/>
				<TextField
					{...register('category')}
					error={!!errors.category}
					helperText={errors.category?.message ? `${errors.category?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Category"
					name="category"
					rows={4}
					autoFocus
				/>
				<Controller
					control={control}
					name="subject_code"
					rules={{
						required: 'Required',
					}}
					defaultValue={'maths'}
					render={({ field }) => (
						<Autocomplete
							options={['maths']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Subject'}
									margin="normal"
									variant="outlined"
									error={!!errors.subject_code}
									helperText={errors.subject_code?.message ? `${errors.subject_code?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
			</Box>
		</Create>
	);
};

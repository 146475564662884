import React from 'react';
import { AppBar, Avatar, Stack, Toolbar, Typography } from '@mui/material';
import { useGetIdentity } from '@refinedev/core';

export const Header: React.FC = () => {
	const { data: user } = useGetIdentity<any>();
	const showUserInfo = user && (user.name || user.avatar);

	return (
		<AppBar color="default" position="sticky" elevation={1}>
			<Toolbar>
				<Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center">
					{showUserInfo && (
						<Stack direction="row" gap="16px" alignItems="center">
							{user.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
							{user.name && <Typography variant="subtitle2">{user?.name}</Typography>}
						</Stack>
					)}
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

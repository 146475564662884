import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, Box, FormGroup, FormControlLabel, Switch, FormControl, OutlinedInput, InputLabel } from '@mui/material';
import useSeriesData from '@hooks/useSeriesData';

export const UserPreferenceEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
		control,
	} = useForm();

	const seriesList = useSeriesData();
	const filteredSeriesList = seriesList?.filter((list: any) => list?.is_original === true);
	const [selectedSeries, setSelectedSeries] = useState<any>(null);

	const seriesId = queryResult?.data?.data.current_series_uuid;

	useEffect(() => {
		if (seriesId) {
			const seriesItem = seriesList?.filter((e: any) => e.uuid === seriesId)[0];
			setSelectedSeries(seriesItem);
		} else {
			setSelectedSeries({});
		}
	}, [seriesId, seriesList]);

	const [checked, setChecked] = useState<boolean>(null);
	const [appOptOut, setAppOptOut] = useState<boolean>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleAppOptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAppOptOut(event.target.checked);
	};

	useEffect(() => {
		setAppOptOut(queryResult?.data?.data?.extra_config?.app_opt_out);
	}, [queryResult?.data?.data?.extra_config?.app_opt_out]);

	useEffect(() => {
		setChecked(queryResult?.data?.data?.link_on);
	}, [queryResult?.data?.data?.link_on]);

	const renderSeriesField = (seriesList: any, selectedSeries: any) => {
		return ({ field }: any) => (
			<Autocomplete
				{...field}
				options={seriesList}
				onChange={(_, value: any) => {
					if (value?.uuid) field.onChange(value?.uuid);
					setSelectedSeries(value);
				}}
				getOptionLabel={(item) => {
					return seriesList?.find((p: any) => item?.uuid && p?.uuid?.toString() === item?.uuid?.toString())?.name ?? '';
				}}
				isOptionEqualToValue={(option, value) => value === undefined || option?.uuid?.toString() === (value?.uuid ?? value)?.toString()}
				value={selectedSeries}
				renderInput={(params) => (
					<TextField
						{...params}
						// defaultValue={seriesName}
						label="current_series_uuid"
						margin="normal"
						variant="outlined"
						error={!!errors?.current_series_uuid}
						helperText={errors.current_series_uuid?.message ? `${errors.current_series_uuid?.message}` : undefined}
						required
					/>
				)}
			/>
		);
	};

	const RenderSeries = (props: any) => {
		const { seriesList, selectedSeries } = props;
		if (!seriesList) return null;
		return (
			<Controller
				control={control}
				name="current_series_uuid"
				defaultValue={selectedSeries}
				render={renderSeriesField(seriesList, selectedSeries)}
			/>
		);
	};

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.name}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					InputLabelProps={{ shrink: true }}
					name="name"
				/>
				<TextField
					{...register('pref_lang')}
					defaultValue={queryResult?.data?.data.pref_lang}
					error={!!errors.pref_lang}
					helperText={errors.pref_lang?.message ? `${errors.pref_lang?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="PrefLang"
					name="pref_lang"
				/>
				<TextField
					{...register('target_content_tags')}
					defaultValue={queryResult?.data?.data.target_content_tags}
					error={!!errors.target_content_tags}
					helperText={errors.target_content_tags?.message ? `${errors.target_content_tags?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Target content tags"
					name="target_content_tags"
				/>
				<TextField
					{...register('chat_slack_handler')}
					defaultValue={queryResult?.data?.data.chat_slack_handler}
					error={!!errors.chat_slack_handler}
					helperText={errors.chat_slack_handler?.message ? `${errors.chat_slack_handler?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="ChatSlackHandler"
					name="chat_slack_handler"
				/>
				<TextField
					{...register('chat_start_time')}
					error={!!errors.chat_start_time}
					helperText={errors.chat_start_time?.message ? `${errors.chat_start_time?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="ChatStartTime"
					name="chat_start_time"
				/>
				<RenderSeries seriesList={filteredSeriesList} selectedSeries={selectedSeries} />
				<TextField
					{...register('current_module_uuid')}
					defaultValue={queryResult?.data?.data.current_module_uuid}
					error={!!errors.current_module_uuid}
					helperText={errors.current_module_uuid?.message ? `${errors.current_module_uuid?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="CurrentModuleUUID"
					name="current_module_uuid"
				/>
				<TextField
					{...register('extra_config.max_chat_time', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data?.extra_config?.max_chat_time}
					error={!!errors.extra_config}
					helperText={errors.extra_config?.message ? `${errors.extra_config?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="MaxChatTime"
					type="number"
					name="extra_config.max_chat_time"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch checked={appOptOut} {...register('extra_config.app_opt_out')} onChange={handleAppOptChange} />}
						label="Android App Opt Out"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>SaturdayPracticeOn</InputLabel>
						<OutlinedInput {...register('saturday_practice_on')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('saturday_practice_on')} />} label="SaturdayPracticeOn" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>SundayPracticeOn</InputLabel>
						<OutlinedInput {...register('sunday_practice_on')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('sunday_practice_on')} />} label="SundayPracticeOn" />
				</FormGroup>
				<TextField
					{...register('weekday_practice_days')}
					error={!!errors.weekday_practice_days}
					defaultValue={queryResult?.data?.data?.weekday_practice_days}
					helperText={errors.weekday_practice_days?.message ? `${errors.weekday_practice_days?.message}` : undefined}
					margin="normal"
					fullWidth
					label="WeekdayPracticeDays"
					name="weekday_practice_days"
				/>
				{checked !== null ? (
					<FormGroup sx={{ marginTop: '16px' }}>
						<FormControlLabel control={<Switch checked={checked} {...register('link_on')} onChange={handleChange} />} label="Link On" />
					</FormGroup>
				) : null}
			</Box>
		</Edit>
	);
};

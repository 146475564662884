import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const ModulesList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'type',
			headerName: 'Type',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'series_name',
			headerName: 'SeriesName',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'difficulty_type',
			headerName: 'Difficulty Type',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'uuid',
			headerName: 'UUID',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'info',
			headerName: 'Info',
			minWidth: 150,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'lang',
			headerName: 'Lang',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'ref_order',
			headerName: 'RefOrder',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.ref_order + '';
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		pagination: {
			current: 1,
			pageSize: 25
		},
		sorters: {
			initial: [{ field: "id", order: "desc" }],
		},
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

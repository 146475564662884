import MathContent from '@components/MathContent';
import { IQuestion } from '@interfaces/index';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

export const QuestionList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'uuid',
			headerName: 'UUID',
			width: 150,
		},
		{
			field: 'descp',
			headerName: 'Descp',
			minWidth: 400,
			flex: 1,
			renderCell: ({ row }: any) => {
				return <MathContent content={row.descp} />;
			},
		},
		{
			field: 'question_image',
			headerName: 'Question Images',
			minWidth: 400,
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: ({ row }: any) => {
				return (
					<div style={{ height: 50 * (row.question_images || []).length }}>
						{(row.question_images || []).map((e: any, i: number) => {
							if (e && !e.startsWith('http')) {
								e = `data:image/png;base64,${e}`;
							}
							return <img src={`${e}`} alt="" key={i} style={{ width: 200 }} />;
						})}
					</div>
				);
			},
		},
		{
			field: 'breakdown_image',
			headerName: 'Breakdown Images',
			minWidth: 400,
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: ({ row }: any) => {
				return (
					<div style={{ height: 50 * (row.breakdown_images || []).length }}>
						{(row.breakdown_images || []).map((e: any, i: number) => {
							if (e && !e.startsWith('http')) {
								e = `data:image/png;base64,${e}`;
							}
							return <img src={`${e}`} alt="" key={i} style={{ width: 200 }} />;
						})}
					</div>
				);
			},
		},
		{
			field: 'medias',
			headerName: 'Medias',
			minWidth: 400,
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: ({ row }: any) => {
				return (row.medias || []).map((e: any) => e.link).join(',');
			},
		},
		{
			field: 'type',
			headerName: 'Type',
			minWidth: 100,
			flex: 1,
		},
		...[0,1,2,3].map((_, i) => {
			return (
				{
					field: `options[${i}]`,
					headerName: `Option ${i+1}`,
					minWidth: 120,
					flex: 1,
					filterable: false,
					sortable: false,
					renderCell: ({ row }: any) => {
						const p = row.options[i] || {};
						return (
							<>
								<span style={{ marginRight: 4 }}>{p.is_correct ? '✅' : ''}</span>
								<MathContent content={p.descp || ''} />
							</>
						);
					},
				}
			)
		}),
		{
			field: 'purpose',
			headerName: 'Purpose',
			minWidth: 120,
			flex: 0.3,
		},
		{
			field: 'difficult_lvl',
			headerName: 'Difficulty lvl',
			minWidth: 120,
			flex: 0.3,
		},
		{
			field: 'key_concepts',
			headerName: 'Key Concepts',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.key_concepts.map((e: any) => e.value).join(' || ');
			},
		},
		{
			field: 'other_concepts',
			headerName: 'Other Concepts',
			minWidth: 120,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.other_concepts.map((e: any) => e.value).join(' || ');
			},
		},
		{
			field: 'tags',
			headerName: 'Tags',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.tags.map((e: any) => e.value).join(' || ');
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 100,
			flex: 0.3,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

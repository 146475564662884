import { Box, TextField, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const UserContactEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="UserId"
					name="user_id"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<TextField
					{...register('type', {
						required: 'Required',
					})}
					error={!!errors.type}
					helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Type"
					name="type"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('country_code', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.country_code}
					helperText={errors.country_code?.message ? `${errors.country_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="CountryCode (0 in case of email)"
					type="number"
					name="country_code"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('info', {
						required: 'Required',
					})}
					error={!!errors.country_code}
					helperText={errors.country_code?.message ? `${errors.country_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					type="text"
					name="info"
					InputLabelProps={{ shrink: true }}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('comms_opted')} defaultChecked={queryResult?.data?.data?.comms_opted} />}
						label="CommsOpted"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const ConceptsEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();
	// const [val, setval] = useState(null);

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('subject_code', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.subject_code}
					error={!!errors.subject_code}
					helperText={errors.subject_code?.message ? `${errors.subject_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Subject"
					InputLabelProps={{ shrink: true }}
					name="subject_code"
				/>
				<TextField
					{...register('title')}
					defaultValue={queryResult?.data?.data.title}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Title"
					name="title"
				/>
				<TextField
					{...register('descp')}
					defaultValue={queryResult?.data?.data.descp}
					error={!!errors.descp}
					helperText={errors.descp?.message ? `${errors.descp?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Descp"
					name="descp"
				/>

				<TextField
					{...register('category')}
					defaultValue={queryResult?.data?.data.category}
					error={!!errors.category}
					helperText={errors.category?.message ? `${errors.category?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Category"
					name="category"
				/>
			</Box>
		</Edit>
	);
};

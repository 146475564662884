import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export enum CreateStep {
	QUESTION_ID,
	PROMPT,
	WO_1,
	WO_2,
	WO_3,
	WO_4,
	WO_RETRY,
	QE_1,
	QE_2,
	QE_RETRY,
	QB_1,
	QB_1_EXP,
	QB_2,
	QB_2_EXP,
	QB_3,
	QB_3_EXP,
	SOLUTION,
	UPLOAD,
	DONE,
}

export interface UploadType {
	type: 'text' | 'audio' | 'video' | 'file' | 'input' | 'boolean' | 'image';
	value: any;
	file?: any;
	fileName?: string;
}

interface State {
	step: number;
	questionId: number;
	question: any;
	data: { [key: string]: UploadType[] };
	reset?: () => void;
}

const initialState: State = {
	step: CreateStep.QUESTION_ID,
	questionId: null,
	question: null,
	data: {},
};

const useContentStore = create<State>()(
	devtools(
		persist(
			(set) => ({
				...initialState,
				reset: () => set({ ...initialState }),
			}),
			{
				name: 'Panel:Content',
				version: 1,
				partialize: (state) => ({
					step: state.step,
					questionId: state.questionId,
					question: state.question,
					data: state.data,
				}),
			},
		),
		{ name: 'Panel:Content:Devtools', trace: true, enabled: import.meta.env.DEV },
	),
);

export default useContentStore;

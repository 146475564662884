import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const InstituteEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.name}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					InputLabelProps={{ shrink: true }}
					name="name"
				/>
				<TextField
					{...register('state')}
					defaultValue={queryResult?.data?.data.state}
					error={!!errors.state}
					helperText={errors.state?.message ? `${errors.state?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="State"
					name="state"
				/>
				<TextField
					{...register('type')}
					defaultValue={queryResult?.data?.data.type}
					error={!!errors.type}
					helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Type"
					name="type"
				/>
				<TextField
					{...register('trial_period')}
					defaultValue={queryResult?.data?.data.trial_period}
					error={!!errors.trial_period}
					helperText={errors.trial_period?.message ? `${errors.trial_period?.message}` : undefined}
					margin="normal"
					type="number"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Trial Period"
					name="trial_period"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('enabled')} defaultChecked={queryResult?.data?.data?.enabled} />}
						label="Enabled"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

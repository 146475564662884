import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MAIN_API_SERVER } from '../../constants';
import Autocomplete from '@mui/material/Autocomplete';

export const BookCreate: React.FC = () => {
	const [options, setOptions] = useState<string[]>([]);

	const {
		saveButtonProps,
		register,
		control,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		fetchOptions();
	}, []);

	const fetchOptions = async () => {
		try {
			const response = await axios.get(`${MAIN_API_SERVER}/api/panel/handlers`);
			const fetchedOptions = response?.data?.data;
			const handlers = fetchedOptions.map((handler: any) => handler?.alias);
			setOptions(handlers);
		} catch (error) {
			console.error('Error fetching options:', error);
		}
	};

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					if (typeof values.classes === 'string') {
						const spls = values.classes
							.split(',')
							.filter((val) => val.trim().length > 0)
							.map((val) => String(val.trim()));
						setValue('classes', spls);
					}

					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
				/>
				<TextField
					{...register('display_name', {
						required: 'Required',
					})}
					error={!!errors.display_name}
					helperText={errors.display_name?.message ? `${errors.display_name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Display Name"
					name="display_name"
				/>
				<TextField
					{...register('author', {
						required: 'Required',
					})}
					error={!!errors.author}
					helperText={errors.author?.message ? `${errors.author?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Author"
					name="author"
				/>
				<Controller
					control={control}
					name="author"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={options}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Author'}
									margin="normal"
									variant="outlined"
									error={!!errors.author}
									helperText={errors.author?.message ? `${errors.author?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="class_code"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={['3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Class'}
									margin="normal"
									variant="outlined"
									error={!!errors.class_code}
									helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_default')} />} label="Is Default?" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_super_default')} />} label="Is Super Default?" />
				</FormGroup>
			</Box>
		</Create>
	);
};

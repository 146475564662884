import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const InstitutePlanEdit: React.FC = () => {
	const {
		saveButtonProps,
		control,
		refineCore: { queryResult },
		register,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	return (
		<Edit
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					if (typeof values.plan_ids === 'string') {
						const spls = getValues()
							.plan_ids.split(',')
							.filter((val) => val.trim().length > 0)
							.map((val) => Number(val.trim()));
						setValue('plan_ids', spls);
					}

					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('ref_id', {
						required: 'Required',
					})}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Ref ID"
					name="ref_id"
					InputLabelProps={{ shrink: true }}
				/>
				<Controller
					control={control}
					name="ref_type"
					rules={{
						required: 'Required',
					}}
					defaultValue={''}
					render={({ field }) => (
						<Autocomplete
							options={['user', 'institute_class', 'institute']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="RefType"
									margin="normal"
									variant="outlined"
									error={!!errors.ref_type}
									helperText={errors.ref_type?.message ? `${errors.ref_type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('plan_ids', {
						required: 'Required',
					})}
					error={!!errors.plan_ids}
					helperText={errors.plan_ids?.message ? `${errors.plan_ids?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Plan ID"
					name="plan_ids"
					InputLabelProps={{ shrink: true }}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('enabled')} defaultChecked={queryResult?.data?.data?.enabled} />}
						label="Enabled"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

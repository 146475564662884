/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import MathContent from './MathContent';

type QuestionItemProps = {
	order?: number;
	content: string;
	images: string[];
};

export const QuestionItem: React.FC<QuestionItemProps> = (props: any) => {
	const { order, content, images } = props;

	return (
		<div data-component="QuestionItem">
			{order && <div>Question: {order}</div>}
			<MathContent content={content} />
			<div>
				{(images || []).map((e: any, i: number) => {
					if (e && !e.startsWith('http')) {
						e = `data:image/png;base64,${e}`;
					}
					return <img src={`${e}`} alt="image" key={i} style={{ width: 200 }} />;
				})}
			</div>
		</div>
	);
};

import { FC, useState } from 'react';
import axios from 'axios';
import { MAIN_API_SERVER } from '../../constants';
import ToastHelper from '@helpers/toast_helper';
import '../../styles/main.css';

const MergeUsers: FC = () => {
	const [primaryUserId, setPrimaryUserId] = useState<any>('');
	const [secdonaryUserId, setSecondaryUserId] = useState<any>('');
	const url = `${MAIN_API_SERVER}/api/operations/merge_users`;

	const mergeUsers = (primaryUserId: number, secondaryUserId: number) => {
		axios
			.post(url, {
				primary_id: Number(primaryUserId),
				secondary_id: Number(secondaryUserId),
			})
			.then(function (response) {
				new ToastHelper().showInfo(response?.data?.data?.code || 'Successfull', 6000, false);
			})
			.catch((err: any) => {
				new ToastHelper().showError(err?.message || 'Oops! Error in merging users right now. Please try again after sometime.');
			});
	};

	return (
		<div className="input_leaderboard">
			<label>
				<b>Merge Users: </b>
			</label>
			<br />
			<br />
			<label>Primary User Id:</label>
			<input disabled={primaryUserId !== ''} className="leaderboard_options" type="number" onChange={(e) => setPrimaryUserId(e.target.value)} />
			<label>Secondary User Id:</label>
			<input
				disabled={secdonaryUserId !== ''}
				className="leaderboard_options"
				type="number"
				onChange={(e) => setSecondaryUserId(e.target.value)}
			/>
			<button className="leaderboard_btn" onClick={() => mergeUsers(primaryUserId, secdonaryUserId)}>
				Submit
			</button>
		</div>
	);
};

export default MergeUsers;

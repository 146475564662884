import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const ProgramSeriesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('program_name', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.program_name}
					error={!!errors.program_name}
					helperText={errors.program_name?.message ? `${errors.program_name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					InputLabelProps={{ shrink: true }}
					name="program_name"
				/>
				<TextField
					{...register('subject_code', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data.subject_code}
					error={!!errors.subject_code}
					helperText={errors.subject_code?.message ? `${errors.subject_code?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Subject Code"
					name="subject_code"
				/>
				<TextField
					{...register('session', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data.session}
					error={!!errors.session}
					helperText={errors.session?.message ? `${errors.session?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Session"
					name="session"
				/>

				<TextField
					{...register('class_code', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data.class_code}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Class Code"
					name="class_code"
				/>
				<TextField
					{...register('series_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.series_id}
					error={!!errors.series_id}
					helperText={errors.series_id?.message ? `${errors.series_id?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Series Id"
					name="series_id"
				/>
				<TextField
					{...register('ref_order', {
						required: 'Required',
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.ref_order}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Ref Order"
					name="ref_order"
					type="number"
				/>
			</Box>
		</Edit>
	);
};

import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const UserInsightList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'user_id',
			headerName: 'UserId',
			width: 150,
		},
		{
			field: 'total_days_streak',
			headerName: 'total_days_streak',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'longest_days_streak',
			headerName: 'longest_days_streak',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'current_week_streak',
			headerName: 'current_week_streak',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				if (!row.id) return;
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'user_id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import React from 'react';
import { useDataGrid, List } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns: GridColDef<any>[] = [
	{
		field: 'id',
		headerName: 'Id',
		type: 'number',
		width: 150,
		renderCell: ({ row }: any) => {
			return row.id + '';
		},
	},
	{
		field: 'module_id',
		headerName: 'module_id',
		minWidth: 150,
		flex: 1,
		renderCell: ({ row }: any) => {
			return row.module_id + '';
		},
	},
	{
		field: 'ref_id',
		headerName: 'ref_id',
		minWidth: 150,
		flex: 1,
		renderCell: ({ row }: any) => {
			return row.ref_id + '';
		},
	},
	{
		field: 'ref_type',
		headerName: 'ref_type',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'ref_order',
		headerName: 'ref_order',
		minWidth: 150,
		flex: 1,
		renderCell: ({ row }: any) => {
			return row.ref_order + '';
		},
	},
	{
		field: 'use_case',
		headerName: 'use_case',
		minWidth: 150,
		flex: 1,
	},
];

export const ModuleEntitiesList: React.FC = () => {

	const { dataGridProps } = useDataGrid<any>({
		pagination: {
			current: 1,
			pageSize: 25
		},
		sorters: {
			initial: [{ field: "id", order: "desc" }],
		},
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

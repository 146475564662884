import { uploadFileCallback } from '@helpers/content_helper';
import useRecorder, { EMPTY_AUDIO_URL, RECORDING } from '@hooks/useRecorder';
import  Button from '@mui/material/Button';
import { secondsToHMS } from '@utils/common';
import { Progress } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

enum UploadState {
	IDLE,
	RECORDING,
	UPLOADING,
	SUCCESS,
	ERROR,
}

interface Props {
	url: string;
	step?: string;
	questionId: number;
	onUpload?: (value: string) => void;
}

const AudioRecorder: FC<Props> = (props) => {
	const [progress, setProgress] = useState(0);
	const [uploadState, setUploadState] = useState(UploadState.IDLE);

	const [audioFile, recorderState, secondsRecording, audioRecorderError, startRecording, pauseRecording, resumeRecording, _, captureAudio] =
		useRecorder('audio');

	const isRecording = useMemo(() => recorderState === RECORDING, [recorderState]);

	const handlePauseClick = () => {
		if (isRecording) {
			pauseRecording();
		} else {
			resumeRecording();
		}
	};

	const startAudioRecording = () => {
		startRecording();
		setUploadState(UploadState.RECORDING);
	};

	const uploadAudioFile = () => {
		setUploadState(UploadState.UPLOADING);
		const file = new File([audioFile], 'audio', { type: 'aac' });
		uploadFileCallback({
			usecase: 'medias',
			randomFileName: true,
			metadata: {
				step: props.step,
				question_id: String(props.questionId),
			},
			onSuccessCallback: ({ url }) => props.onUpload(url),
		})({
			file: file,
			onError: () => setUploadState(UploadState.ERROR),
			onSuccess: () => setUploadState(UploadState.SUCCESS),
			onProgress: ({ percent }) => setProgress(percent),
		});
	};

	useEffect(() => {
		if (audioFile !== EMPTY_AUDIO_URL) {
			uploadAudioFile();
		}
	}, [audioFile]);

	if (props.url) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(0,0,0,0.1)', borderRadius: '12px', padding: '16px' }}>
				<audio src={props.url} controls />
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(0,0,0,0.1)', borderRadius: '12px', padding: '16px' }}>
			{audioRecorderError ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>Some Error Occured (Give Permissions)!</div>
					<Button variant="contained" onClick={startAudioRecording}>
						Retry
					</Button>
				</div>
			) : uploadState === UploadState.UPLOADING ? (
				<Progress type="circle" percent={progress} size={100} />
			) : uploadState === UploadState.ERROR ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>Some Error Occured While Uploading!</div>
					<Button variant="contained" onClick={uploadAudioFile}>
						Retry
					</Button>
				</div>
			) : uploadState === UploadState.IDLE ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>You are ready to start recording</div>
					<Button variant="contained" onClick={startAudioRecording}>
						Start Recording
					</Button>
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
					<div style={{ fontSize: '20px', fontWeight: '600' }}>{secondsToHMS(Number(secondsRecording))}</div>
					<div style={{ fontSize: '20px', fontWeight: '500' }}>{isRecording ? 'You are speaking' : 'Paused'}</div>
					<div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
						<Button variant="outlined" onClick={handlePauseClick}>
							Pause
						</Button>
						<Button variant="contained" onClick={captureAudio}>
							Upload
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default AudioRecorder;

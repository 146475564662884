import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeleteButton, EditButton, useDataGrid, List } from '@refinedev/mui';

export const UserContactList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'user_id',
			headerName: 'UserId',
			width: 100,
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 100,
		},
		{
			field: 'type',
			headerName: 'Type',
			width: 100,
		},
		{
			field: 'country_code',
			headerName: 'CountryCode',
			width: 100,
		},
		{
			field: 'info',
			headerName: 'Info',
			width: 100,
		},
		{
			field: 'is_verified',
			headerName: 'IsVerified',
			width: 100,
		},
		{
			field: 'verified_at',
			headerName: 'VerifiedAt',
			width: 100,
		},
		{
			field: 'comms_opted',
			headerName: 'CommsOpted',
			width: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
				return (<>
					<EditButton hideText recordItemId={row.id} />
					<DeleteButton hideText recordItemId={row.id} />
				</>);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const UserInsightEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('total_days_streak', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data?.total_days_streak}
					error={!!errors.name}
					helperText={errors.total_days_streak?.message ? `${errors.total_days_streak?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Total Days Streak"
					type="number"
					InputLabelProps={{ shrink: true }}
					name="total_days_streak"
				/>
				<TextField
					{...register('longest_days_streak', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data?.longest_days_streak}
					error={!!errors.name}
					helperText={errors.longest_days_streak?.message ? `${errors.longest_days_streak?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Longest Day Streak"
					InputLabelProps={{ shrink: true }}
					name="longest_days_streak"
				/>
				<TextField
					{...register('current_week_streak', {
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data?.current_week_streak}
					error={!!errors.name}
					helperText={errors.current_week_streak?.message ? `${errors.current_week_streak?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Current Week Streak"
					type="number"
					InputLabelProps={{ shrink: true }}
					name="current_week_streak"
				/>
			</Box>
		</Edit>
	);
};

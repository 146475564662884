import { IGasQuestion } from '@interfaces/index';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

export const SeedList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
		},
		{
			field: 'content',
			headerName: 'Content',
			width: 250,
		},
		{
			field: 'url',
			headerName: 'Image Url',
			width: 250,
		},
		{
			field: 'tags',
			headerName: 'Tags',
			minWidth: 150,
		},
		{
			field: 'subjects',
			headerName: 'Subjects',
			minWidth: 150,
			renderCell: ({ row }) => {
				return <div>{row.info?.subjects?.join(', ')}</div>;
			},
		},
		{
			field: 'classes',
			headerName: 'Classes',
			minWidth: 150,
			renderCell: ({ row }) => {
				return <div>{row.info?.classes?.join(', ')}</div>;
			},
		},
		{
			field: 'emotion',
			headerName: 'Emotions',
			minWidth: 150,
			renderCell: ({ row }) => {
				return <div>{row.info?.emotion?.join(', ')}</div>;
			},
		},
		{
			field: 'interest',
			headerName: 'Interests',
			minWidth: 150,
			renderCell: ({ row }) => {
				return <div>{row.info?.interests?.join(', ')}</div>;
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IGasQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'asc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

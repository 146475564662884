import AudioRecorder from '@components/Dialogs/AudioRecorder';
import VideoRecorder from '@components/Dialogs/VideoRecorder';
import MathContent from '@components/MathContent';
import { uploadFileCallback } from '@helpers/content_helper';
import { Button, TextField } from '@mui/material';
import { UploadType } from '@stores/content';
import { Upload } from 'antd';
import { FC, Fragment, memo, useEffect, useState } from 'react';

interface UploadElementProps {
	id: string;
	label: string;
	data: UploadType[];
	questionId: number;
	showCopyPrevious?: boolean;
	excludeInputs?: string[];
	onlyInputs?: string[];
	showPrevious: boolean;
	onSkip: () => void;
	onBack: () => void;
	onCopy: () => void;
	onChanges: (value: any) => void;
	onSubmit: (value: any) => void;
}

const UploadQues: FC<UploadElementProps> = (props) => {
	const [showAddRow] = useState(true);
	const [data, setData] = useState<UploadType[]>(props.data);

	useEffect(() => {
		setData([...props.data]);
	}, [props.data]);

	useEffect(() => {
		// const lastType = data.at(-1)?.type;
		// setShowAddRow(lastType !== 'input');
		if (props.onChanges) props.onChanges(data);
	}, [data]);

	const renderElem = (d: any, index: number) => {

		if (d.type==='text') {
			return <TextField
					value={d.value}
					label={`Text ${index + 1}`}
					style={{ flex: '1' }}
					onChange={(e) => {
						setData((val) => {
							const x = [...val];
							x[index].value = e.target.value;
							return x;
						});
					}}
				/>
		}

		if (d.type==='audio') {
				return <AudioRecorder
							url={d.value}
							step={props.id}
							questionId={props.questionId}
							onUpload={(url) => {
								setData((val) => {
									const x = [...val];
									x[index].value = url;
									return x;
								});
							}}
						/>
		}

		if (d.type==='video') {
			return <VideoRecorder
						url={d.value}
						step={props.id}
						questionId={props.questionId}
						onUpload={(url) => {
						setData((val) => {
							const x = [...val];
							x[index].value = url;
							return x;
						});
					}}
					/>
		}

		if (d.type==='image') {
			return <img src={d.value} width={250}/>
		}

		if (d.type === 'file') {
			return <Upload.Dragger
						name="file"
						accept="mp4, m4a, aac, png, jpeg, jpg"
						customRequest={uploadFileCallback({
							usecase: 'medias',
							randomFileName: true,
							metadata: {
								step: props.id,
								question_id: String(props.questionId),
							},
							onSuccessCallback: (e: any) => {
								setData((val) => {
									const x = [...val];
									x[index].value = e.url;
									return x;
								});
							},
						})}
						maxCount={1}
						multiple={false}
					>
						<p className="ant-upload-text">Drag & drop a file in this area</p>
					</Upload.Dragger>
		}

		if (d.type==='input') {
			return (<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: '1' }}>
									{Array.from({ length: 4 }).map((_, i) => (
										<div key={i} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
											<TextField
												value={d.value?.[i]?.text}
												label={`Input ${i + 1}`}
												onChange={(e) => {
													setData((val) => {
														const x = [...val];
														if (!x[index].value) x[index].value = [];
														if (!x[index].value[i]) x[index].value[i] = {};
														x[index].value[i].text = e.target.value;
														return x;
													});
												}}
											/>
											<div style={{ display: 'flex', gap: '8px' }}>
												<input
													type="checkbox"
													name={`input_${i}`}
													defaultChecked={d.value?.[i]?.correct}
													onChange={(e) => {
														setData((val) => {
															const x = [...val];
															if (!x[index].value) x[index].value = [];
															if (!x[index].value[i]) x[index].value[i] = {};
															x[index].value[i].correct = Boolean( e.target.checked);
															return x;
														});
													}}
												/>
												<label htmlFor={`input_${i}`}> Correct option</label>
											</div>
										</div>
									))}
								</div>);
		}
		return null;
	}

	return (
		<>
			<div style={{ fontSize: '20px', fontWeight: '700' }}>Enter details for {props.label}</div>
			{data.filter((e) => e.type!=='boolean').map((d, index) => {
				return (
					<Fragment key={`${props.id}_${index}`}>
						<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
							{renderElem(d, index)}
							<Button
								variant="outlined"
								onClick={() => {
									const val = [...data];
									val.splice(index, 1);
									setData([...val]);
								}}
							>
								Remove
							</Button>
						</div>
						{d.type === 'text' && d.value.length > 0 && d.value.includes('$') && (
							<div>
								<div style={{ fontSize: '16px', fontWeight: '600' }}>Preview Text {index + 1}</div>
								<div style={{ background: '#ccc', display: 'block', padding: '4px 12px', borderRadius: '8px', marginTop: '8px' }}>
									<MathContent content={d.value} />
								</div>
							</div>
						)}
					</Fragment>
				);
			})}
			{showAddRow && (
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll', gap: '8px' }}>
					{['text', 'audio', 'video', 'file', 'input']
						.filter((type) => !props.excludeInputs.includes(type))
						.map((type) => (
							<Button
								variant="outlined"
								style={{ flexShrink: '0' }}
								key={type}
								onClick={() => setData((val) => [...val, { type: type as any, value: '' }])}
							>
								+ {type}
							</Button>
						))}
					{
						props.id.includes('retry') && (
							<div style={{ display: 'flex', gap: '8px' }}>
												<input
													type="checkbox"
													name={`input_retry`}
													value="Input"
													defaultChecked={props.data.filter((e) => e.type==='boolean')[0]?.value}
													onChange={(e) => {
														setData([{type: 'boolean', value:  e.target.checked}]);
													}}
												/>
												<label htmlFor={`input_retry`}> {props.label}</label>
											</div>
						)
					}
				</div>
			)}
			{props.showCopyPrevious && (
				<Button variant="contained" onClick={props.onCopy}>
					Copy From Previous Step
				</Button>
			)}
			<Button
				variant="contained"
				onClick={() => {
					if (data?.length > 0) {
						props.onSubmit(data);
						setData([]);
					} else {
						props.onSkip();
					}
				}}
			>
				Next Step
			</Button>
			{
				props.showPrevious && (
					<Button variant="outlined" onClick={props.onBack}>
						Previous Step
					</Button>
				)
			}
			
		</>
	);
};

UploadQues.defaultProps = {
	excludeInputs: [],
};

export default memo(UploadQues, (prevProps: any, nextProps: any) => {
	if (
		JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
		prevProps.questionId === nextProps.questionId &&
		prevProps.showCopyPrevious === nextProps.showCopyPrevious &&
		prevProps.id === nextProps.id
	) {
		return true; // props are equal
	}
	return false; // props are not equal -> update the component
});
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { RoomProps } from '@interfaces/index';
import { MAIN_API_SERVER } from '../constants';

const useFetchRooms = () => {
	const url = `${MAIN_API_SERVER}/api/panel/monitor_rooms`;
	const [rooms, setRooms] = useState<RoomProps[]>([]);
	const prevRoomsRef = useRef<RoomProps[]>([]);

	useEffect(() => {
		const fetchRooms = async () => {
			try {
				const res = await axios.get(url);
				const newRooms = res?.data?.data;
				if (JSON.stringify(prevRoomsRef.current) !== JSON.stringify(newRooms)) {
					setRooms(newRooms);
					prevRoomsRef.current = newRooms;
				}
			} catch (error) {
				console.error('Error fetching rooms:', error);
			}
		};

		fetchRooms();
		const interval = setInterval(fetchRooms, 30000);
		return () => clearInterval(interval);
	}, [url]);

	return rooms;
};

export default useFetchRooms;

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const PlanCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
					autoFocus
				/>
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
				/>
				<TextField
					{...register('duration', {
						required: 'Required',
					})}
					error={!!errors.duration}
					helperText={errors.duration?.message ? `${errors.duration?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Duration"
					name="duration"
				/>
				<TextField
					{...register('base_price', {
						required: 'Required',
					})}
					error={!!errors.base_price}
					helperText={errors.base_price?.message ? `${errors.base_price?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Base Price"
					name="base_price"
				/>
				<TextField
					{...register('discounted_price', {
						required: 'Required',
					})}
					error={!!errors.discounted_price}
					helperText={errors.discounted_price?.message ? `${errors.discounted_price?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Discounted Price"
					name="discounted_price"
				/>
				<TextField
					{...register('start_date', {
						required: 'Required',
						valueAsDate: true,
					})}
					error={!!errors.start_date}
					helperText={errors.start_date?.message ? `${errors.start_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="StartDate"
					name="start_date"
					type="date"
					placeholder=""
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('enabled')} />} label="Enabled" />
				</FormGroup>
			</Box>
		</Create>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { List } from '@refinedev/mui';
import ToastHelper from '@helpers/toast_helper';
import APIHelper from '@helpers/api_helper';
import { QuestionItemForm } from '@components/QuestionItemForm';

export const AllFilterQuestionPage: React.FC = () => {
	const [questions, setQuestions] = useState([]);
	const [searchParams] = useSearchParams();
	let type = searchParams.get('type');
	let tags = searchParams.get('tags');
	let search_txt = searchParams.get('search_txt');
	let start = searchParams.get('start');
	let end = searchParams.get('end');
	let values = searchParams.get('values');

	useEffect(() => {
		window.scrollTo(0, 0);
		if (start && end) {
			fetchQuestionsByStartEnd(start, end);
		} else {
			fetchQuestions(type, tags, search_txt);
		}
	}, []);

	const fetchQuestions = (type: any, tags: any, search_txt: any) => {
		new ToastHelper().showInfo('Loading Questions');
		new APIHelper()
			.fetchFilterQuestions(type, tags, search_txt)
			.then((data: any) => {
				setQuestions(data);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of questions right now. Please try again after sometime.');
			});
	};

	const fetchQuestionsByStartEnd = (start: any, end: any) => {
		new ToastHelper().showInfo('Loading Questions');
		new APIHelper()
			.fetchQuestionsByStartEnd(start, end)
			.then((data: any) => {
				setQuestions(data);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of questions right now. Please try again after sometime.');
			});
	};

	return (
		<div data-component="AllFilterQuestionPage">
			<div className={'questions'}>
				<div>
					<b>
						Type: {type}, Tags: {values}
					</b>
				</div>
				<List breadcrumb={false}>
					<table style={{ border: 1 }}>
						<thead>
							<tr>
								<td>Labels</td>
								<td>ID</td>
								<td>BaseCode</td>
								<td className={'tbl-title'}>Topic</td>
								<td>Order</td>
								<td className={'tbl-descp'}>Description</td>
								<td>Type</td>
								<td>Option1</td>
								<td>Option2</td>
								<td>Option3</td>
								<td>Option4</td>
								<td>Difficulty Lvl</td>
								<td>Avg Time Taken</td>
								<td>Key Concepts</td>
								<td>Other Concepts</td>
								<td>Approved</td>
								<td>Submit</td>
							</tr>
						</thead>
						<tbody>
							{questions.map((e, i) => {
								return <QuestionItemForm input={e} index={i} key={i} />;
							})}
						</tbody>
					</table>
				</List>
			</div>
		</div>
	);
};

import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeleteButton } from '@refinedev/mui';

export const FeatureFlagList: React.FC = () => {

	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'ref_id',
			headerName: 'RefId',
			width: 150,
			type: 'number'
		},
		{
			field: 'ref_type',
			headerName: 'RefType',
			width: 150,
		},
		{
			field: 'flag_name',
			headerName: 'FlagName',
			width: 100,
		},
		{
			field: 'flag_value',
			headerName: 'FlagValue',
			width: 100,
		},
		{
			field: 'flag_config',
			headerName: 'FlagConfig',
			width: 150,
		},
		{
			field: 'priority',
			headerName: 'Priority',
			width: 100,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
				return (
					<>
						<EditButton hideText recordItemId={row.id} />
						<DeleteButton hideText recordItemId={row.id} />
					</>
				);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 150,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

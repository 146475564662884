import React from 'react';
import { useDataGrid, List, EditButton, DeleteButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import MathContent from '@components/MathContent';

export const ConversationContentList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'ref_id',
			headerName: 'RefId',
			width: 100,
		},
		{
			field: 'ref_type',
			headerName: 'RefType',
			width: 100,
		},
		{
			field: 'data_key',
			headerName: 'DataKey',
			width: 300,
		},
		{
			field: 'data_value',
			headerName: 'DataValue',
			width: 600,
			renderCell: function render({ row }) {
				return <MathContent content={row.data_value} />;
			},
		},
		{
			field: 'lang',
			headerName: 'Lang',
			width: 50,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
				return (
					<>
						<EditButton hideText recordItemId={row.id} />
						<DeleteButton hideText recordItemId={row.id} />
					</>
				);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<any>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

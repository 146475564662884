import React, { useState } from 'react';
import Select from 'react-select';
import APIHelper from '../helpers/api_helper';
import ToastHelper from '../helpers/toast_helper';

type SectionItemFormProps = {
	input: any;
	index: number;
};

export const SectionItemForm: React.FC<SectionItemFormProps> = (props) => {
	const { input } = props;
	const [options, setOptions] = useState([]);
	const topicId = input.id;

	const handleInputChange = (inputValue: any) => {
		let toastHelper = new ToastHelper();
		// console.log("inputValue --- ",inputValue)
		if (inputValue.length >= 3 && inputValue.trim() !== '') {
			new APIHelper()
				.fetchSearchedConcepts(inputValue.trim())
				.then((data) => {
					let optionsArr = data.data.map(({ id, title }: any) => ({ value: id, label: title }));
					setOptions(optionsArr);
				})
				.catch((e) => {
					toastHelper.showError(`Error in updating section - ${e.message}`);
				});
		}
	};
	const handleChange = (selectedOptions: any) => {
		let selectedOptionsArr: any[] = selectedOptions.map(({ value }: any) => {
			return value;
		});
		let data = { section_id: topicId, concept_ids: selectedOptionsArr };
		let toastHelper = new ToastHelper();
		toastHelper.showInfo('Updating Section');
		new APIHelper()
			.updateSectionOnChange(data)
			.then(() => {
				toastHelper.showInfo('Updated Section');
			})
			.catch((e) => {
				toastHelper.showError(`Error in updating section - ${e.message}`);
			});
	};

	// const setValues = (name: any) => {
	//     return ({ target: { value } }: any) => {
	//         fields[name] = value;
	//     };
	// }

	return (
		<tr>
			<td>{input.id}</td>
			<td>
				{input.descp} {input.title}
			</td>
			<td className={'tbl-descp'}>
				<Select
					options={options}
					onInputChange={handleInputChange}
					onChange={handleChange}
					defaultValue={input.concepts}
					isClearable={false}
					className="basic-multi-select"
					classNamePrefix="select"
					isMulti
					name={`concept-${input.id}[]`}
				/>
			</td>
		</tr>
	);
};

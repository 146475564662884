import AudioRecorder from '@components/Dialogs/AudioRecorder';
import VideoRecorder from '@components/Dialogs/VideoRecorder';
import MathContent from '@components/MathContent';
import APIHelper from '@helpers/api_helper';
import { Button, TextField, Chip } from '@mui/material';
import useContentStore, { CreateStep, UploadType } from '@stores/content';
import { isEmpty, isEqual, uniqWith } from 'lodash';
import { FC, useEffect, useState } from 'react';
import UploadQues from './Upload';

interface QuestionDetailProps {
	question: any;
}

const ALL_KEYS = [
	'prompt',
	'option1_expln',
	'option2_expln',
	'option3_expln',
	'option4_expln',
	'is_wo_retry',
	'qe_lvl1',
	'qe_lvl2',
	'is_qe_retry',
	'qb_lvl1',
	'qb_lvl1_expln',
	'qb_lvl2',
	'qb_lvl2_expln',
	'qb_lvl3',
	'qb_lvl3_expln',
	'solution',
];

const tabKeyNames = [
	'QUESTION_ID',
	'PROMPT',
	'WO_1',
	'WO_2',
	'WO_3',
	'WO_4',
	'WO_RETRY',
	'QE_1',
	'QE_2',
	'QE_RETRY',
	'QB_1',
	'QB_1_EXP',
	'QB_2',
	'QB_2_EXP',
	'QB_3',
	'QB_3_EXP',
	'SOLUTION',
	'UPLOAD',
];

const QuestionDetails: FC<QuestionDetailProps> = (props) => {
	if (!props.question) return null;

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div style={{ fontSize: '20px', fontWeight: '700' }}>Question Details:</div>
			<div style={{ padding: '8px 0 16px' }}>
				<MathContent content={props.question.descp} />
			</div>
			<div style={{ padding: '0 0 16px' }}>Type: {props.question.type}</div>
			{(props.question?.options || []).map((ques, index) => (
				<div key={index} style={{ display: 'flex', gap: '8px' }}>
					{ques.ref_order}: <MathContent content={ques.descp} /> {ques.is_correct && '(Correct)'}
				</div>
			))}
		</div>
	);
};

const ContentCreate: FC = () => {
	const [uploading, setUploading] = useState(false);
	const [lang, setLang] = useState('he');
	const [contentType, setContentType] = useState('question');

	const step = useContentStore((state) => state.step);
	const questionId = useContentStore((state) => state.questionId);
	const question = useContentStore((state) => state.question);
	const data = useContentStore((state) => state.data);

	useEffect(() => {
		if (!(Number(questionId) > 0)) {
			useContentStore.setState({ question: null });
			return;
		}

		const helper = new APIHelper();
		helper.fetchContentsById(contentType === 'question' ? 'questions' : 'contents', questionId).then((res) => {
			useContentStore.setState({ question: res });
			helper.fetchQuestionContentById(contentType === 'question' ? 'questions' : 'contents', res.uuid, lang).then((res) => {
				// if (Object.keys(res.data).length === 0) return;

				const newData: { [key: string]: UploadType[] } = {};
				for (const x of Object.keys(res.data)) {
					if (typeof res.data[x] === 'boolean') {
						newData[x] = [];
						newData[x] = [
							...newData[x],
							{
								type: 'boolean',
								value: res.data[x],
							},
						];
					}
					if (Array.isArray(res.data[x])) {
						for (let index = 0; index < res.data[x].length; index++) {
							const y = res.data[x][index];
							if (y.type === 'info') {
								if (!newData[x]) newData[x] = [];
								newData[x] = [
									...newData[x],
									{
										type: 'text',
										value: y.body,
									},
								];
							} else if (y.type === 'media') {
								if (y.sub_type) {
									if (!newData[x]) newData[x] = [];
									newData[x] = [
										...newData[x],
										{
											type: y.sub_type.replace('media_', ''),
											value: y.payload.link,
										},
									];
								}
							} else if (y.type === 'input') {
								if (!newData[x]) newData[x] = [];
								newData[x] = [
									...newData[x],
									{
										type: 'input',
										value: y.payload.options,
									},
								];
							}
						}
					}
				}
				useContentStore.setState({ data: newData });
			});
		});
	}, [questionId, lang]);

	const moveToPrevStep = () => {
		if (step === CreateStep.WO_4 && question.options[2] && question.options[2].is_correct) {
			useContentStore.setState({ step: step - 2 });
			return;
		} else if (step === CreateStep.WO_3 && question.options[1] && question.options[1].is_correct) {
			useContentStore.setState({ step: step - 2 });
			return;
		} else if (step === CreateStep.WO_2 && question.options[0] && question.options[0].is_correct) {
			useContentStore.setState({ step: step - 2 });
			return;
		}
		useContentStore.setState({ step: step - 1 });
	};

	const renderStep = ({
		key,
		label,
		excludeInputs,
		onlyInputs,
		copyInputs,
		skipStep,
		onSubmitCallback,
	}: {
		key: string;
		label: string;
		excludeInputs?: string[];
		onlyInputs?: string[];
		copyInputs?: string[];
		skipStep?: number;
		onSubmitCallback?: any;
	}) => {
		return (
			<UploadQues
				id={key}
				key={key}
				label={label}
				questionId={questionId}
				data={data[key] ?? []}
				excludeInputs={excludeInputs}
				onlyInputs={onlyInputs}
				showCopyPrevious={!!copyInputs}
				showPrevious={step > CreateStep.PROMPT}
				onChanges={(value) => {
					useContentStore.setState({ data: { ...data, [key]: value } });
				}}
				onSubmit={(value) => {
					if (onSubmitCallback) onSubmitCallback();
					else if (key !== 'solution') useContentStore.setState({ step: step + 1 });
					else if (key === 'solution') useContentStore.setState({ step: CreateStep.UPLOAD });
					useContentStore.setState({ data: { ...data, [key]: value } });
				}}
				onSkip={() => {
					if (skipStep) {
						useContentStore.setState({ step: skipStep });
					} else {
						if (onSubmitCallback) onSubmitCallback();
						else if (key !== 'solution') useContentStore.setState({ step: step + 1 });
						else if (key === 'solution') useContentStore.setState({ step: CreateStep.UPLOAD });
					}
				}}
				onCopy={() => {
					if (!copyInputs) return;
					let dataToCopy: UploadType[] = [];
					for (let index = 0; index < copyInputs.length; index++) {
						if (data[copyInputs[index]]) {
							dataToCopy = [...dataToCopy, ...data[copyInputs[index]]];
						}
					}
					dataToCopy = uniqWith(dataToCopy, isEqual);
					useContentStore.setState({ data: { ...data, [key]: [...dataToCopy] } });
				}}
				onBack={moveToPrevStep}
			/>
		);
	};

	const submitData = () => {
		setUploading(true);
		const items = {};
		for (const key of Object.keys(data)) {
			if (key.includes('retry')) {
				if (data[key][0]) {
					items[key] = data[key][0]['value'];
				}
			} else {
				items[key] = data[key]
					.map((e) => {
						if (e.type === 'text') {
							return { type: 'info', sub_type: 'info_rich_text', body: e.value };
						} else if (e.type === 'video') {
							return { type: 'media', sub_type: 'media_video', payload: { link: e.value } };
						} else if (e.type === 'audio') {
							return { type: 'media', sub_type: 'media_audio', payload: { link: e.value } };
						} else if (e.type === 'image') {
							return { type: 'media', sub_type: 'media_image', payload: { link: e.value } };
						} else if (e.type === 'file') {
							if (e.value.endsWith('.mp4')) {
								return { type: 'media', sub_type: 'media_video', payload: { link: e.value } };
							} else if (e.value.endsWith('.aac') || e.value.endsWith('.m4a')) {
								return { type: 'media', sub_type: 'media_audio', payload: { link: e.value } };
							} else if (e.value.endsWith('.jpeg') || e.value.endsWith('.png') || e.value.endsWith('.jpg')) {
								return { type: 'media', sub_type: 'media_image', payload: { link: e.value } };
							}
							return null;
						} else if (e.type === 'input') {
							return { type: 'input', sub_type: 'input_options_single', payload: { options: e.value.filter((e) => !isEmpty(e)) } };
						}
						return;
					})
					.filter((e) => !!e);
			}
		}
		new APIHelper()
			.updateConversationContent({ ref_type: contentType, ref_id: Number(questionId), items, lang })
			.then(() => useContentStore.setState({ step: CreateStep.DONE }))
			.catch(() => alert('Error in uploading data. Report to admin'))
			.finally(() => setUploading(false));
	};

	const renderStepElem = () => {
		switch (step) {
			case CreateStep.QUESTION_ID:
				return (
					<>
						<div style={{ fontSize: '20px', fontWeight: '700' }}>Select Content Type:</div>
						<div style={{ display: 'flex', gap: '8px' }}>
							<Chip
								label="Question"
								color="primary"
								variant={contentType === 'question' ? 'filled' : 'outlined'}
								onClick={() => setContentType('question')}
							/>
							<Chip
								label="Misc Content"
								color="primary"
								variant={contentType === 'content' ? 'filled' : 'outlined'}
								onClick={() => setContentType('content')}
							/>
						</div>
						<div style={{ fontSize: '20px', fontWeight: '700' }}>Enter Content ID here:</div>
						<TextField
							label="Enter content ID"
							type="number"
							value={questionId}
							onChange={(e) => useContentStore.setState({ questionId: e.target.value === '' ? null : Number(e.target.value) })}
						/>
						{contentType === 'question' && <QuestionDetails question={question} />}

						<div style={{ fontSize: '20px', fontWeight: '700' }}>Select Language:</div>
						<div style={{ display: 'flex', gap: '8px' }}>
							<Chip label="Hinglish" color="primary" variant={lang === 'he' ? 'filled' : 'outlined'} onClick={() => setLang('he')} />
							<Chip label="English" color="primary" variant={lang === 'en' ? 'filled' : 'outlined'} onClick={() => setLang('en')} />
						</div>
						<Button
							variant="contained"
							disabled={question === null && contentType === 'question'}
							onClick={() => useContentStore.setState({ step: CreateStep.PROMPT })}
						>
							Confirm content
						</Button>
					</>
				);
			case CreateStep.PROMPT:
				return renderStep({
					key: 'prompt',
					label: 'Question Prompt',
					excludeInputs: ['input'],
					onSubmitCallback: () => {
						if (contentType === 'content') useContentStore.setState({ step: CreateStep.UPLOAD });
						if (question.options[0].is_correct) useContentStore.setState({ step: CreateStep.WO_2 });
						else useContentStore.setState({ step: CreateStep.WO_1 });
					},
				});
			case CreateStep.WO_1:
				return renderStep({
					key: 'option1_expln',
					label: 'Wrong option 1',
					onSubmitCallback: () => {
						if (!question?.options[1]) useContentStore.setState({ step: CreateStep.QE_1 });
						if (question.options[1].is_correct) useContentStore.setState({ step: CreateStep.WO_3 });
						else useContentStore.setState({ step: CreateStep.WO_2 });
					},
				});
			case CreateStep.WO_2:
				return renderStep({
					key: 'option2_expln',
					label: 'Wrong option 2',
					copyInputs: ['option1_expln'],
					onSubmitCallback: () => {
						if (!question?.options[2]) useContentStore.setState({ step: CreateStep.QE_1 });
						if (question.options[2].is_correct) useContentStore.setState({ step: CreateStep.WO_4 });
						else useContentStore.setState({ step: CreateStep.WO_3 });
					},
				});
			case CreateStep.WO_3:
				return renderStep({
					key: 'option3_expln',
					label: 'Wrong option 3',
					copyInputs: ['option1_expln', 'option2_expln'],
					onSubmitCallback: () => {
						if (question?.options[3]?.is_correct || !question?.options[3]) useContentStore.setState({ step: CreateStep.QE_1 });
						else useContentStore.setState({ step: CreateStep.WO_4 });
					},
				});
			case CreateStep.WO_4:
				return renderStep({ key: 'option4_expln', label: 'Wrong option 4', copyInputs: ['option1_expln', 'option2_expln', 'option3_expln'] });
			case CreateStep.WO_RETRY:
				return renderStep({
					key: 'is_wo_retry',
					label: 'Question Retry After WO',
					excludeInputs: ['file', 'audio', 'video', 'input', 'text'],
				});
			case CreateStep.QE_1:
				return renderStep({ key: 'qe_lvl1', label: 'Question explanation 1', skipStep: 8 });
			case CreateStep.QE_2:
				return renderStep({ key: 'qe_lvl2', label: 'Question explanation 2' });
			case CreateStep.QE_RETRY:
				return renderStep({
					key: 'is_qe_retry',
					label: 'Question Retry After QE',
					excludeInputs: ['file', 'audio', 'video', 'input', 'text'],
				});
			case CreateStep.QB_1:
				return renderStep({ key: 'qb_lvl1', label: 'Question breakdown 1', skipStep: 16 });
			case CreateStep.QB_1_EXP:
				return renderStep({ key: 'qb_lvl1_expln', label: 'Question breakdown Expln 1' });
			case CreateStep.QB_2:
				return renderStep({ key: 'qb_lvl2', label: 'Question breakdown 2', skipStep: 16 });
			case CreateStep.QB_2_EXP:
				return renderStep({ key: 'qb_lvl2_expln', label: 'Question breakdown Expln 2' });
			case CreateStep.QB_3:
				return renderStep({ key: 'qb_lvl3', label: 'Question breakdown 3', skipStep: 16 });
			case CreateStep.QB_3_EXP:
				return renderStep({ key: 'qb_lvl3_expln', label: 'Question breakdown Expln 3' });
			case CreateStep.SOLUTION:
				return renderStep({ key: 'solution', label: 'Solution', excludeInputs: ['input'] });
			default:
				return null;
		}
	};

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#fff',
				borderRadius: '12px',
				padding: '16px',
				gap: '16px',
				justifyContent: 'flex-start',
			}}
		>
			{step !== CreateStep.QUESTION_ID && (
				<div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
					{(contentType === 'question' ? tabKeyNames : ['Prompt']).map((e, i) => {
						const selected = step === i;
						return (
							<Chip
								style={{
									borderRadius: '8px',
								}}
								key={i}
								label={e}
								color="info"
								variant={selected ? 'filled' : 'outlined'}
								onClick={() => {
									useContentStore.setState({ step: i });
								}}
							></Chip>
						);
					})}
				</div>
			)}

			{step !== CreateStep.QUESTION_ID && contentType === 'question' && <QuestionDetails question={question} />}
			{renderStepElem()}

			{step === CreateStep.UPLOAD && (
				<>
					{ALL_KEYS.map((x, index) => {
						return (
							data[x] && (
								<div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
									<div style={{ fontSize: '18px', fontWeight: '600' }}>For {x}</div>
									{data[x].map((y, index) => {
										if (!Array.isArray(y.value)) {
											return (
												<div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
													<div style={{ fontSize: '16px', fontWeight: '500' }}>Type - {y.type}</div>
													{y.type === 'audio' ||
													(y.type === 'file' && (y.value.endsWith('.mp3') || y.value.endsWith('.aac'))) ? (
														<AudioRecorder url={y.value} questionId={questionId} />
													) : y.type === 'video' || (y.type === 'file' && y.value.endsWith('.mp4')) ? (
														<VideoRecorder url={y.value} questionId={questionId} />
													) : y.type === 'image' ||
													  (y.type === 'file' && (y.value.endsWith('.png') || y.value.endsWith('.jpeg'))) ? (
														<img src={y.value} width={100} height={100} />
													) : (
														<div>Value - {y.value + ''}</div>
													)}
												</div>
											);
										} else {
											return (
												<div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
													<div style={{ fontSize: '16px', fontWeight: '500' }}>Type - {y.type}</div>
													<div>
														Value -{' '}
														{y.value
															.map((e) => {
																if (e && e.correct) {
																	return `${e.text} (Correct)`;
																}
																return e ? e.text || null : null;
															})
															.filter((e) => !!e)
															.join(',')}
													</div>
												</div>
											);
										}
										return null;
									})}
								</div>
							)
						);
					})}
					<Button variant="contained" disabled={uploading} onClick={submitData}>
						Upload All Data
					</Button>
					<Button variant="outlined" onClick={moveToPrevStep}>
						Previous Step
					</Button>
				</>
			)}
			{step === CreateStep.DONE ? (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							fontSize: '40px',
							fontWeight: '700',
							height: '100%',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						Done Upload
					</div>
					<Button variant="contained" onClick={() => useContentStore.getState().reset()}>
						New
					</Button>
				</>
			) : (
				<Button variant="contained" color="error" onClick={() => useContentStore.getState().reset()}>
					Discard Data
				</Button>
			)}
		</div>
	);
};

export default ContentCreate;

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

import { IAssignmentQuestion } from 'interfaces';

const columns: GridColDef<any>[] = [
	{
		field: 'id',
		headerName: 'Id',
		type: 'number',
		width: 150,
		renderCell: ({ row }: any) => {
			return row.id + '';
		},
	},
	{
		field: 'name',
		headerName: 'Name',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'state',
		headerName: 'State',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'type',
		headerName: 'Type',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'trial_period',
		headerName: 'Trial Period',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'enabled',
		headerName: 'Enabled',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: 'Actions',
		renderCell: function render({ row }) {
			return <EditButton hideText recordItemId={row.id} />;
		},
		align: 'center',
		headerAlign: 'center',
		minWidth: 80,
	},
];

export const InstituteList: React.FC = () => {
	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		pagination: {
			current: 1,
			pageSize: 25,
		},
		sorters: {
			initial: [{ field: 'id', order: 'desc' }],
		},
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const ModuleEntitiesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();
	// const [val, setval] = useState(null);

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('module_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data?.module_id}
					error={!!errors.module_id}
					helperText={errors.module_id?.message ? `${errors.module_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="ModuleId"
					InputLabelProps={{ shrink: true }}
					name="module_id"
				/>
				<TextField
					{...register('ref_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					defaultValue={queryResult?.data?.data.entity_id}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="RefId"
					name="ref_id"
				/>
				<TextField
					{...register('ref_type')}
					defaultValue={queryResult?.data?.data.entity_type}
					error={!!errors.ref_type}
					helperText={errors.ref_type?.message ? `${errors.ref_type?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="RefType"
					name="ref_type"
				/>
			</Box>
		</Edit>
	);
};

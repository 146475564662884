import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const SeedCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					const values = getValues();

					const subjects = values.subjects
						.split(',')
						.filter((val) => val.trim().length > 0)
						.map((val) => Number(String(val).trim()));
					// setValue('subjects', '');

					const classes = values.classes
						.split(',')
						.filter((val) => val.trim().length > 0)
						.map((val) => String(val).trim());
					// setValue('classes', '');

					const emotion = values.emotion
						.split(',')
						.filter((val) => val.trim().length > 0)
						.map((val) => String(val).trim());
					// setValue('emotion', '');

					const interest = values.interest
						.split(',')
						.filter((val) => val.trim().length > 0)
						.map((val) => String(val).trim());
					// setValue('interest', '');

					const info = {
						subjects,
						classes,
						emotion,
						interest,
					};

					setValue('info', info);
					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('content', {})}
					error={!!errors.content}
					helperText={errors.content?.message ? `${errors.content?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Content"
					name="content"
					autoFocus
				/>
				<TextField
					{...register('url', {})}
					error={!!errors.url}
					helperText={errors.url?.message ? `${errors.url?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Image Url"
					name="url"
				/>
				<TextField
					{...register('tags')}
					error={!!errors.tags}
					helperText={errors.tags?.message ? `${errors.tags?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Tags"
					name="tags"
				/>
				<TextField
					{...register('subjects')}
					error={!!errors.subjects}
					helperText={errors.subjects?.message ? `${errors.subjects?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Subjects"
					name="subjects"
				/>
				<TextField
					{...register('classes')}
					error={!!errors.classes}
					helperText={errors.classes?.message ? `${errors.classes?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Classes"
					name="classes"
				/>
				<TextField
					{...register('emotion')}
					error={!!errors.emotion}
					helperText={errors.emotion?.message ? `${errors.emotion?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Emotion"
					name="emotion"
				/>
				<TextField
					{...register('interest')}
					error={!!errors.interest}
					helperText={errors.interest?.message ? `${errors.interest?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Interest"
					name="interest"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('enabled')} defaultChecked={true} />} label="Enabled" />
				</FormGroup>
			</Box>
		</Create>
	);
};

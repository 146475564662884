import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MAIN_API_SERVER } from '../../constants';
import { Box, TextField, Autocomplete, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

export const AssignmentCreate: React.FC = () => {
	const [options, setOptions] = useState<string[]>([]);
	const {
		saveButtonProps,
		register,
		control,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		fetchOptions();
	}, []);

	const fetchOptions = async () => {
		try {
			const response = await axios.get(`${MAIN_API_SERVER}/api/panel/handlers`);
			const fetchedOptions = response?.data?.data;
			const handlers = fetchedOptions.map((handler: any) => handler?.alias);
			setOptions(handlers);
		} catch (error) {
			console.error('Error fetching options:', error);
		}
	};

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('title', {
						required: 'Required',
					})}
					error={!!errors.title}
					helperText={errors.title?.message ? `${errors.title?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Title"
					name="title"
					autoFocus
				/>
				<TextField
					{...register('info')}
					error={!!errors.info}
					helperText={errors.info?.message ? `${errors.info?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					name="info"
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					defaultValue={'homework'}
					render={({ field }) => (
						<Autocomplete
							options={['homework', 'guided_homework', 'test']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="access_type"
					rules={{
						required: 'Required',
					}}
					defaultValue={'open'}
					render={({ field }) => (
						<Autocomplete
							options={['limited', 'open']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'AccessType'}
									margin="normal"
									variant="outlined"
									error={!!errors.access_type}
									helperText={errors.access_type?.message ? `${errors.access_type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="handler"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={options}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Handler'}
									margin="normal"
									variant="outlined"
									error={!!errors.handler}
									helperText={errors.handler?.message ? `${errors.handler?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('is_template')} />} label="Is Template" />
					<TextField
						{...register('question_ids', {
							required: 'Required',
						})}
						error={!!errors.question_ids}
						helperText={errors.question_ids?.message ? `${errors.question_ids?.message}` : undefined}
						margin="normal"
						fullWidth
						label="Question UUIds"
						name="question_ids"
						multiline
						rows={4}
						autoFocus
					/>
					<TextField
						{...register('series_ids')}
						error={!!errors.question_ids}
						helperText={errors.series_ids?.message ? `${errors.series_ids?.message}` : undefined}
						margin="normal"
						fullWidth
						label="Series UUIds"
						name="series_ids"
						multiline
						rows={2}
						autoFocus
					/>
					<TextField
						{...register('classroom_id', {
							required: 'Required',
						})}
						type={'number'}
						error={!!errors.classroom_id}
						helperText={errors.classroom_id?.message ? `${errors.classroom_id?.message}` : undefined}
						margin="normal"
						fullWidth
						label="ClassroomId"
						name="classroom_id"
					/>
				</FormGroup>
			</Box>
		</Create>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const AssignmentInvitationEdit: React.FC = () => {
	const {
		saveButtonProps,
		// refineCore: { queryResult },
		register,
		control,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('assignment_code')}
					error={!!errors.assignment_code}
					helperText={errors.assignment_code?.message ? `${errors.assignment_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="AssignmentCode"
					name="assignment_code"
					InputLabelProps={{ shrink: true }}
					autoFocus
				/>
				<Controller
					control={control}
					name="invitation_type"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={['whatsapp', 'mail', 'phone']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							defaultValue={'whatsapp'}
							renderInput={(params) => (
								<TextField
									{...params}
									label="InvitationType"
									margin="normal"
									variant="outlined"
									error={!!errors.invitation_type}
									helperText={errors.invitation_type?.message ? `${errors.invitation_type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('info', {
						required: 'Required',
					})}
					error={!!errors.info}
					helperText={errors.info?.message ? `${errors.info?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					name="info"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('target_time', {
						required: 'Required',
					})}
					error={!!errors.target_time}
					helperText={errors.target_time?.message ? `${errors.target_time?.message}` : undefined}
					margin="normal"
					fullWidth
					label="TargetTime (HH:mm:ss)"
					name="target_time"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('target_date', {
						required: 'Required',
						valueAsDate: true,
					})}
					error={!!errors.target_date}
					helperText={errors.target_date?.message ? `${errors.target_date?.message}` : undefined}
					margin="normal"
					fullWidth
					label="TargetDate (YYYY-MM-DD)"
					name="target_date"
					InputLabelProps={{ shrink: true }}
				/>
			</Box>
		</Edit>
	);
};

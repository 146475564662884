import { uploadFileCallback } from '@helpers/content_helper';
import { Autocomplete, Box, FormControl, FormControlLabel, FormGroup, InputLabel, OutlinedInput, Switch, TextField } from '@mui/material';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Upload } from 'antd';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

export const QuestionCreate: React.FC = () => {
	const [files, setFiles] = useState<string[]>([]);
	const {
		saveButtonProps,
		register,
		control,
		setValue,
		formState: { errors },
	} = useForm();

	const onSuccessUpload = (file) => {
		setFiles((val) => [...val, file.url]);
	};

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				onClick: (e) => {
					setValue('images', files);
					saveButtonProps.onClick(e);
				},
			}}
		>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('descp', {
						required: 'Required',
					})}
					error={!!errors.descp}
					helperText={errors.descp?.message ? `${errors.descp?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Descp"
					name="descp"
					multiline
					rows={4}
					autoFocus
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['SCQ', 'SA', 'TF', 'FTB', 'TFR', 'MCQ']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="difficult_lvl"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['easy', 'medium', 'hard', 'hots']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Difficulty Lvl'}
									margin="normal"
									variant="outlined"
									error={!!errors.difficult_lvl}
									helperText={errors.difficult_lvl?.message ? `${errors.difficult_lvl?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="purpose"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['application', 'understanding']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Purpose'}
									margin="normal"
									variant="outlined"
									error={!!errors.purpose}
									helperText={errors.purpose?.message ? `${errors.purpose?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="status"
					rules={{
						required: 'Required',
					}}
					defaultValue={'draft'}
					render={({ field }) => (
						<Autocomplete
							options={['draft', 'reviewed', 'hold', 'rejected']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Status'}
									margin="normal"
									variant="outlined"
									error={!!errors.status}
									helperText={errors.status?.message ? `${errors.status?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>Option 1</InputLabel>
						<OutlinedInput {...register('options[0].descp')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('options[0].is_correct')} />} label="Option 1 Correct" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>Option 2</InputLabel>
						<OutlinedInput {...register('options[1].descp')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('options[1].is_correct')} />} label="Option 2 Correct" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>Option 3</InputLabel>
						<OutlinedInput {...register('options[2].descp')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('options[2].is_correct')} />} label="Option 3 Correct" />
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControl>
						<InputLabel>Option 4</InputLabel>
						<OutlinedInput {...register('options[3].descp')} />
					</FormControl>
					<FormControlLabel control={<Switch {...register('options[3].is_correct')} />} label="Option 4 Correct" />
				</FormGroup>
				<h3 style={{ padding: '16px 0px 12px' }}>Image Upload</h3>
				<Upload.Dragger
					name="file"
					customRequest={uploadFileCallback({ usecase: 'medias', onSuccessCallback: onSuccessUpload, randomFileName: true })}
					listType="picture"
					maxCount={5}
					multiple
					onRemove={(file) => {
						const newFiles = files.filter((f) => f !== (file.originFileObj as any).url);
						setFiles([...newFiles]);
					}}
				>
					<p className="ant-upload-text">Drag & drop a file in this area</p>
				</Upload.Dragger>
			</Box>
		</Create>
	);
};

import axios from 'axios';
import { CONTENT_API_SERVER, MAIN_API_SERVER } from '../constants';
export default class APIHelper {
	fetchQuestions(grade: string, subject: string, book: string, chapter: string) {
		return new Promise((resolve, reject) => {
			fetch(CONTENT_API_SERVER + `/api/panel/questions/search?grade=${grade}&subject=${subject}&book=${book}&chapter=${chapter}`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	fetchFilterQuestions(type: string, tags: string, search_txt?: string) {
		return new Promise((resolve, reject) => {
			fetch(
				search_txt
					? CONTENT_API_SERVER + `/api/panel/questions/search?type=${type}&tags=${tags}&search_txt=${search_txt}`
					: CONTENT_API_SERVER + `/api/panel/questions/search?type=${type}&tags=${tags}`,
			)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	fetchQuestionsByStartEnd(start: number, end: number) {
		return new Promise((resolve, reject) => {
			fetch(CONTENT_API_SERVER + `/api/panel/questions/search?start=${start}&end=${end}`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	async fetchQuestionsByIds(ids: string) {
		let data = await axios.post(CONTENT_API_SERVER + `/api/panel/questions/fetch2`, {
			questions: ids,
		});
		return data.data;
	}
	async fetchContentsById(contentType: string, id: number) {
		let data = await axios.get(CONTENT_API_SERVER + `/api/panel/${contentType}/${id}`);
		return data.data;
	}

	fetchQuestionContentById = async (contentType: string, uuid: string, lang: string) => {
		let data = await axios.get(CONTENT_API_SERVER + `/api/conversation_contents/${contentType}/${uuid}?from=panel&lang=${lang}`);
		return data.data;
	};

	uploadHandler(uploadFile: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/operations/questions/images`, uploadFile);
	}

	chatMediaUploadHandler(uploadFile: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/operations/conversation_contents/medias`, uploadFile, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	validateQuestionTxt(text: any) {
		return axios.post(
			CONTENT_API_SERVER + `/api/panel/operations/validate`,
			{ text },
			{
				headers: {
					'content-type': 'multipart/form-data',
				},
			},
		);
	}

	uploadFileToS3 = async (path: string, body: any, onUploadProgress?: any) => {
		const headers = {
			'Content-Type': body.type,
		};
		try {
			const res = await axios.put(path, body, {
				onUploadProgress: onUploadProgress,
				headers,
			});
			return res.data;
		} catch (err: any) {
			throw err;
		}
	};

	getQuestionMediaUrl = async (id: number, type: string, ext: string) => {
		const response = await axios.post(MAIN_API_SERVER + `/api/panel/question/link`, { id, file_type: type, file_ext: ext });
		const { link } = response.data;
		return link;
	};

	fetchSections(grade: string, subject: string, book: string) {
		return new Promise((resolve, reject) => {
			fetch(CONTENT_API_SERVER + `/api/panel/sections/all?grade=${grade}&subject=${subject}&book=${book}&withConcept=true`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	fetchConceptGraphs() {
		return new Promise((resolve, reject) => {
			fetch(CONTENT_API_SERVER + `/api/panel/concepts/graph`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	updateQuestion(questionId: string, data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/questions/${questionId}`, data);
	}
	updateBulkQuestion(data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/questions/update`, data);
	}
	updateSection(topicId: string, data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/sections/${topicId}`, data);
	}
	updateConcept(topicId: string, data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/concepts/${topicId}`, data);
	}
	async fetchSearchedConcepts(searchText: any) {
		let data = await axios.post(CONTENT_API_SERVER + `/api/panel/concepts/search`, {
			searchStr: searchText,
		});
		return data.data;
	}
	updateSectionOnChange(data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/sections/map-concept`, data);
	}
	updateConversationContent(data: any) {
		return axios.post(CONTENT_API_SERVER + `/api/panel/conversation_contents/direct`, data);
	}
	loginApi(payload: any) {
		return axios.post(MAIN_API_SERVER + `/api/users/password-login`, payload);
	}

	fetchLearderBoard(institute: any, section: any, start_date: any, end_date: any) {
		return new Promise((resolve, reject) => {
			fetch(MAIN_API_SERVER + `/api/panel/leaderboard?institute=${institute}&section=${section}&start_date=${start_date}&end_date=${end_date}`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	fetchAssignmentLinks(institute: any, section: any, target_date: any) {
		return new Promise((resolve, reject) => {
			fetch(MAIN_API_SERVER + `/api/panel/assignment_links?institute=${institute}&section=${section}&date=${target_date}`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	fetchQuestionsForAgents(assignmentId: number) {
		return new Promise((resolve, reject) => {
			fetch(MAIN_API_SERVER + `/api/panel/assignments/${assignmentId}/detail`)
				.then((resp) => resp.json())
				.then((data) => {
					return resolve(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

import { ChevronLeft, ChevronRight, Dashboard, ExpandLess, ExpandMore, ListOutlined, Logout, MenuRounded } from '@mui/icons-material';
import { Box, Button, Collapse, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import {
	CanAccess,
	ITreeMenu,
	useIsExistAuthentication,
	useLogout,
	useMenu,
	useNavigation,
	useRefineContext,
	useRouterContext,
	useTitle,
	useTranslate,
} from '@refinedev/core';
import React, { useState } from 'react';
import { Title as DefaultTitle } from '../title';
import './slider.css';
import { TOKEN_KEY } from '../../../authProvider';

export const Sider: React.FC = () => {
	const [collapsed, setCollapsed] = useState(false);
	const [opened, setOpened] = useState(false);
	const isAgent = localStorage.getItem(TOKEN_KEY) === 'agent';

	const drawerWidth = () => {
		if (collapsed) return 64;
		return 200;
	};

	const t = useTranslate();
	const { hasDashboard } = useRefineContext();
	const { Link } = useRouterContext();

	const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
	const isExistAuthentication = useIsExistAuthentication();
	const { mutate: logout } = useLogout();
	const Title = useTitle();

	const [open, setOpen] = useState<{ [k: string]: any }>({});

	const navigate = useNavigation();

	React.useEffect(() => {
		setOpen((previousOpen) => {
			const previousOpenKeys: string[] = Object.keys(previousOpen);
			const uniqueKeys = new Set([...previousOpenKeys, ...defaultOpenKeys]);
			const uniqueKeysRecord = Object.fromEntries(Array.from(uniqueKeys.values()).map((key) => [key, true]));
			return uniqueKeysRecord;
		});
	}, [defaultOpenKeys]);

	const RenderToTitle = Title ?? DefaultTitle;

	const handleClick = (key: string) => {
		setOpen({ ...open, [key]: !open[key] });
	};

	const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
		return tree.map((item: ITreeMenu) => {
			const { icon, label, route, name, children, parentName } = item;
			const isOpen = open[route || label || ''] || false;

			const isSelected = route === selectedKey;
			const isNested = !(parentName === undefined);

			if (children.length > 0) {
				return (
					<div key={route}>
						<Tooltip title={label ?? name} placement="right" disableHoverListener={!collapsed} arrow>
							<ListItemButton
								onClick={() => {
									if (collapsed) {
										setCollapsed(false);
										if (!isOpen) {
											handleClick(route || label || '');
										}
									} else {
										handleClick(route || label || '');
									}
								}}
								sx={{
									pl: isNested ? 4 : 2,
									justifyContent: 'center',
									'&.Mui-selected': {
										'&:hover': {
											backgroundColor: 'transparent',
										},
										backgroundColor: 'transparent',
									},
								}}
							>
								<ListItemIcon
									sx={{
										justifyContent: 'center',
										minWidth: 36,
										color: 'primary.contrastText',
									}}
								>
									{icon ?? <ListOutlined />}
								</ListItemIcon>
								<ListItemText
									primary={label}
									primaryTypographyProps={{
										noWrap: true,
										fontSize: '14px',
										fontWeight: isSelected ? 'bold' : 'normal',
									}}
								/>
								{!collapsed && (isOpen ? <ExpandLess /> : <ExpandMore />)}
							</ListItemButton>
						</Tooltip>
						{!collapsed && (
							<Collapse in={open[route || label || '']} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									{renderTreeView(children, selectedKey)}
								</List>
							</Collapse>
						)}
					</div>
				);
			}

			return (
				<CanAccess key={route} resource={name.toLowerCase()} action="list">
					<Tooltip title={label ?? name} placement="right" disableHoverListener={!collapsed} arrow>
						<ListItemButton
							component={Link}
							href={route}
							to={route}
							selected={isSelected}
							onClick={() => {
								setOpened(false);
							}}
							sx={{
								pl: isNested ? 4 : 2,
								py: isNested ? 1.25 : 1,
								'&.Mui-selected': {
									'&:hover': {
										backgroundColor: 'transparent',
									},
									backgroundColor: 'transparent',
								},
								justifyContent: 'center',
							}}
						>
							<ListItemIcon
								sx={{
									justifyContent: 'center',
									minWidth: 36,
									color: 'primary.contrastText',
								}}
							>
								{icon ?? <ListOutlined />}
							</ListItemIcon>
							<ListItemText
								primary={label}
								primaryTypographyProps={{
									noWrap: true,
									fontSize: '14px',
									fontWeight: isSelected ? 'bold' : 'normal',
								}}
							/>
						</ListItemButton>
					</Tooltip>
				</CanAccess>
			);
		});
	};

	const renderListItem = (linkTo: string, title: string) => {
		return (
			<ListItemButton
				component={Link}
				href={linkTo}
				to={linkTo}
				selected={selectedKey === '/'}
				onClick={() => {
					navigate.push(linkTo);
				}}
				sx={{
					pl: 2,
					py: 1,
					'&.Mui-selected': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
						backgroundColor: 'transparent',
					},
					justifyContent: 'center',
				}}
			>
				<ListItemIcon
					sx={{
						justifyContent: 'center',
						minWidth: 36,
						color: 'primary.contrastText',
					}}
				>
					<Dashboard />
				</ListItemIcon>
				<ListItemText
					primary={t('dashboard.title', title)}
					primaryTypographyProps={{
						noWrap: true,
						fontSize: '14px',
						fontWeight: selectedKey === '/' ? 'bold' : 'normal',
					}}
				/>
			</ListItemButton>
		);
	};

	const customPages = (renderListItem: (link: string, name: string) => any) => {
		if (isAgent) return [];
		return [
			renderListItem('/whatsappbotv2', 'Whatsapp Bot V2'),
			renderListItem('/whatsappbotv2', 'Whatsapp Bot V2'),
			renderListItem('/generate', 'Generate Link'),
			renderListItem('/bulkupload', 'Bulk Upload'),
			renderListItem('/content_create', 'Content V2'),
			renderListItem('/linkpreferences', 'Link Preferences'),
			renderListItem('/mergeusers', 'Merge Users'),
			renderListItem('/live_monitoring', 'Live Monitoring'),
		];
	};

	const drawer = (
		<List disablePadding sx={{ mt: 1, color: 'primary.contrastText' }}>
			{hasDashboard ? (
				<Tooltip title={t('dashboard.title', 'Dashboard')} placement="right" disableHoverListener={!collapsed} arrow>
					<ListItemButton
						component={Link}
						href="/"
						to="/"
						selected={selectedKey === '/'}
						onClick={() => {
							setOpened(false);
						}}
						sx={{
							pl: 2,
							py: 1,
							'&.Mui-selected': {
								'&:hover': {
									backgroundColor: 'transparent',
								},
								backgroundColor: 'transparent',
							},
							justifyContent: 'center',
						}}
					>
						<ListItemIcon
							sx={{
								justifyContent: 'center',
								minWidth: 36,
								color: 'primary.contrastText',
							}}
						>
							<Dashboard />
						</ListItemIcon>
						<ListItemText
							primary={t('dashboard.title', 'Dashboard')}
							primaryTypographyProps={{
								noWrap: true,
								fontSize: '14px',
								fontWeight: selectedKey === '/' ? 'bold' : 'normal',
							}}
						/>
					</ListItemButton>
				</Tooltip>
			) : null}
			{renderTreeView(menuItems, selectedKey)}
			{/* {renderListItem('/class/concepts/graph', 'Concept Graph')}
			{renderListItem('/sections/6th/maths/ncert', 'Section Update')}
			{renderListItem('/questions/6th/maths/ncert/1', 'Question Update')}
			{renderListItem('/leaderboard', 'Leader Board')}
			{renderListItem('/whatsappbot', 'Whatsapp Bot')} */}
			{...customPages(renderListItem)}
			{/* {renderListItem('/assignment_links/ngs/A/2022-10-30', 'Assignment Links')} */}

			{isExistAuthentication && (
				<Tooltip title={t('buttons.logout', 'Logout')} placement="right" disableHoverListener={!collapsed} arrow>
					<ListItemButton key="logout" onClick={() => logout()} sx={{ justifyContent: 'center' }}>
						<ListItemIcon
							sx={{
								justifyContent: 'center',
								minWidth: 36,
								color: 'primary.contrastText',
							}}
						>
							<Logout />
						</ListItemIcon>
						<ListItemText
							primary={t('buttons.logout', 'Logout')}
							primaryTypographyProps={{
								noWrap: true,
								fontSize: '14px',
							}}
						/>
					</ListItemButton>
				</Tooltip>
			)}
		</List>
	);

	return (
		<Box display="flex">
			<Box
				sx={{
					width: { xs: drawerWidth() },
					display: {
						xs: 'none',
						md: 'block',
					},
					transition: 'width 0.3s ease',
				}}
			/>
			<Box
				component="nav"
				sx={{
					position: 'fixed',
					zIndex: 1101,
					width: { sm: drawerWidth() },
					display: 'flex',
				}}
			>
				<Drawer
					variant="temporary"
					open={opened}
					onClose={() => setOpened(false)}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { sm: 'block', md: 'none' },
						'& .MuiDrawer-paper': {
							width: 256,
							bgcolor: 'secondary.main',
						},
					}}
				>
					<Box
						sx={{
							height: 64,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							overflow: 'hidden',
						}}
					>
						<RenderToTitle collapsed={false} />
					</Box>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					PaperProps={{ elevation: 1 }}
					sx={{
						display: { xs: 'none', md: 'block' },
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							bgcolor: 'secondary.main',
							overflow: 'hidden',
							transition: 'width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
						},
					}}
					open
				>
					<Box
						sx={{
							height: 64,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							overflow: 'hidden',
						}}
					>
						<RenderToTitle collapsed={collapsed} />
					</Box>
					<Box
						className="drawer-list"
						sx={{
							flexGrow: 1,
							overflowX: 'hidden',
							overflowY: 'auto',
						}}
					>
						{drawer}
					</Box>
					<Button
						sx={{
							background: 'rgba(0,0,0,.5)',
							color: 'primary.contrastText',
							textAlign: 'center',
							borderRadius: 0,
							borderTop: '1px solid #ffffff1a',
						}}
						fullWidth
						size="large"
						onClick={() => setCollapsed((prev) => !prev)}
					>
						{collapsed ? <ChevronRight /> : <ChevronLeft />}
					</Button>
				</Drawer>
				<Box
					sx={{
						display: { xs: 'block', md: 'none' },
						position: 'fixed',
						top: '8px',
						left: '0px',
						borderRadius: '0 6px 6px 0',
						bgcolor: 'secondary.main',
						zIndex: 1199,
						width: '36px',
					}}
				>
					<IconButton sx={{ color: '#fff', width: '36px' }} onClick={() => setOpened((prev) => !prev)}>
						<MenuRounded />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
};

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const InstituteClassesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('institute_id', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.institute_id}
					error={!!errors.institute_id}
					helperText={errors.institute_id?.message ? `${errors.institute_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Institute ID"
					name="institute_id"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('class_code', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data.class_code}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Class Code"
					name="class_code"
				/>
				<TextField
					type="number"
					{...register('trial_period', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data.trial_period}
					error={!!errors.trial_period}
					helperText={errors.trial_period?.message ? `${errors.trial_period?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Trial Period"
					name="trial_period"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('is_active')} defaultChecked={queryResult?.data?.data?.is_active} />}
						label="Is Active?"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('is_gas_on')} defaultChecked={queryResult?.data?.data?.is_gas_on} />}
						label="Is Gas On?"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

import { FormGroup, FormControl, InputLabel, OutlinedInput, FormControlLabel, Box, Autocomplete, Switch, TextField } from '@mui/material';
import { Input, Typography, Upload, UploadFile } from 'antd';
import { Controller } from 'react-hook-form';
import { uploadFileCallback } from '@helpers/content_helper';
import { useEffect, useState } from 'react';

export const QuestionEdit: React.FC = () => {

	const [files, setFiles] = useState<string[]>([]);
	const [initialFiles, setInitialFiles] = useState<UploadFile[]>([]);
	const { 
		saveButtonProps,
		refineCore: { queryResult },
		register,
		control,
		formState: { errors },
	} = useForm();

	const onSuccessUpload = (file) => {
		setFiles((val) => [...val, file.url]);
	};

	const resData = queryResult?.data?.data;

	useEffect(() => {
		if (resData?.question_images) {
			setFiles(resData?.question_images);
			setInitialFiles(resData?.question_images.map((e) => {
				return {uid: e.split('/').reverse()[0], name: e.split('/').reverse()[0], url: e} as UploadFile;
			}))
		}
	}, [resData?.question_images])

	const { Title } = Typography;

	const renderOptions = () => {
		return (
			<>
				<FormGroup sx={{ marginTop: '16px' }}>
					<Input {...register('options[0].id')} hidden={true} />
					<FormControl>
						<InputLabel>Option 1</InputLabel>
						<OutlinedInput {...register('options[0].descp')} />
					</FormControl>
					<FormControlLabel
						control={
							<Switch {...register('options[0].is_correct')} defaultChecked={(queryResult?.data?.data?.options[0] || {}).is_correct} />
						}
						label="Option 1 Correct"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<Input {...register('options[1].id')} hidden={true} />
					<FormControl>
						<InputLabel>Option 2</InputLabel>
						<OutlinedInput {...register('options[1].descp')} />
					</FormControl>
					<FormControlLabel
						control={
							<Switch {...register('options[1].is_correct')} defaultChecked={(queryResult?.data?.data?.options[1] || {}).is_correct} />
						}
						label="Option 2 Correct"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<Input {...register('options[2].id')} hidden={true} />
					<FormControl>
						<InputLabel>Option 3</InputLabel>
						<OutlinedInput {...register('options[2].descp')} />
					</FormControl>
					<FormControlLabel
						control={
							<Switch {...register('options[2].is_correct')} defaultChecked={(queryResult?.data?.data?.options[2] || {}).is_correct} />
						}
						label="Option 3 Correct"
					/>
				</FormGroup>
				<FormGroup sx={{ marginTop: '16px' }}>
					<Input {...register('options[3].id')} hidden={true} />
					<FormControl>
						<InputLabel>Option 4</InputLabel>
						<OutlinedInput {...register('options[3].descp')} />
					</FormControl>
					<FormControlLabel
						control={
							<Switch {...register('options[3].is_correct')} defaultChecked={(queryResult?.data?.data?.options[3] || {}).is_correct} />
						}
						label="Option 4 Correct"
					/>
				</FormGroup>
			</>
		);
	};

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('descp', {
						required: 'Required',
					})}
					error={!!errors.descp}
					helperText={errors.descp?.message ? `${errors.descp?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Descp"
					name="descp"
					multiline
					rows={4}
					autoFocus
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['SCQ', 'SA', 'TF', 'FTB', 'TFR', 'MCQ']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="difficult_lvl"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['easy', 'medium', 'hard', 'hots']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Difficulty Lvl'}
									margin="normal"
									variant="outlined"
									error={!!errors.difficult_lvl}
									helperText={errors.difficult_lvl?.message ? `${errors.difficult_lvl?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="purpose"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['application', 'knowledge', 'understanding']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Purpose'}
									margin="normal"
									variant="outlined"
									error={!!errors.purpose}
									helperText={errors.purpose?.message ? `${errors.purpose?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<Controller
					control={control}
					name="status"
					rules={{
						required: 'Required',
					}}
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['draft', 'reviewed', 'hold', 'rejected']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Status'}
									margin="normal"
									variant="outlined"
									error={!!errors.status}
									helperText={errors.status?.message ? `${errors.status?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>

				{renderOptions()}
			</Box>
			<Title level={5}>Image Upload</Title>
			<Upload.Dragger name="file" fileList={initialFiles} customRequest={uploadFileCallback({ usecase: 'medias', onSuccessCallback: onSuccessUpload, randomFileName: true })} listType="picture" maxCount={5} multiple onRemove={(file) => {
						const newFiles = files.filter((f) => f !== (file.originFileObj as any).url);
						setFiles([...newFiles]);
					}}>
				<p className="ant-upload-text">Drag & drop a file in this area</p>
			</Upload.Dragger>
		</Edit>
	);
};

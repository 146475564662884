import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const SeedClassesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('seed_id', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.seed_id}
					error={!!errors.seed_id}
					helperText={errors.seed_id?.message ? `${errors.seed_id?.message}` : undefined}
					margin="normal"
					fullWidth
					type="number"
					label="Seed ID"
					name="seed_id"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('user_id', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.user_id}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					type="number"
					label="User ID"
					name="user_id"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					{...register('ref_id', {
						required: 'Required',
					})}
					defaultValue={queryResult?.data?.data?.ref_id}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					type="number"
					label="Ref ID"
					name="ref_id"
					InputLabelProps={{ shrink: true }}
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel
						control={<Switch {...register('enabled')} defaultChecked={queryResult?.data?.data?.enabled} />}
						label="Enabled"
					/>
				</FormGroup>
			</Box>
		</Edit>
	);
};

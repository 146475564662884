import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const UserHolidayEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.ref_id?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.user_id}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="UserId"
					name="user_id"
					autoFocus
				/>
				<TextField
					{...register('start_date', {
						required: 'Required',
						valueAsDate: true,
					})}
					error={!!errors.start_date}
					helperText={errors.start_date?.message ? `${errors.start_date?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.start_date}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="StartDate"
					name="start_date"
					type="date"
				/>
				<TextField
					{...register('end_date', {
						required: 'Required',
						valueAsDate: true,
					})}
					error={!!errors.end_date}
					helperText={errors.end_date?.message ? `${errors.end_date?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.end_date}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="EndDate"
					type="date"
					name="end_date"
				/>
			</Box>
		</Edit>
	);
};

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeleteButton, EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

import { IAssignmentQuestion } from 'interfaces';

export const SeriesList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'title',
			headerName: 'title',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.title + '';
			},
		},
		{
			field: 'name',
			headerName: 'name',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.name + '';
			},
		},
		{
			field: 'author',
			headerName: 'author',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.author + '';
			},
		},
		{
			field: 'uuid',
			headerName: 'uuid',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.uuid + '';
			},
		},
		{
			field: 'lang',
			headerName: 'lang',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'ref_order',
			headerName: 'Ref Order',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'classes',
			headerName: 'Classes',
			minWidth: 150,
		},
		{
			field: 'is_original',
			headerName: 'is Original',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'is_guided_completed',
			headerName: 'guided upload flag',
			minWidth: 150,
			flex: 1,
		},

		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return (<>
							<EditButton hideText recordItemId={row.id} />
							<DeleteButton hideText recordItemId={row.id} />
						</>);
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

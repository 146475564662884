import React from 'react';
import { useDataGrid, List } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { ICategory } from 'interfaces';
import MathContent from '@components/MathContent';

export const ConceptList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'subject_code',
			headerName: 'Subject',
			width: 150,
		},
		{
			field: 'title',
			headerName: 'Title',
			minWidth: 400,
			flex: 1,
			renderCell: ({ row }: any) => {
				return <MathContent content={row.title} />;
			},
		},
		{
			field: 'descp',
			headerName: 'Descp',
			minWidth: 400,
			flex: 1,
			renderCell: ({ row }: any) => {
				return <MathContent content={row.descp} />;
			},
		},
		{
			field: 'category',
			headerName: 'Category',
			width: 150,
		},
	];

	const { dataGridProps } = useDataGrid<ICategory>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const SimilarUsersCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		control,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('primary_user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.primary_user_id}
					helperText={errors.primary_user_id?.message ? `${errors.primary_user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="PrimaryUserId"
					name="primary_user_id"
					autoFocus
				/>
				<TextField
					{...register('secondary_user_id')}
					error={!!errors.secondary_user_id}
					helperText={errors.secondary_user_id?.message ? `${errors.secondary_user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="SecondaryUserId"
					name="secondary_user_id"
				/>
				<Controller
					control={control}
					name="state"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={['none', 'migrated']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'state'}
									margin="normal"
									variant="outlined"
									error={!!errors.state}
									helperText={errors.state?.message ? `${errors.state?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('migrated_timestamp')}
					error={!!errors.migrated_timestamp}
					helperText={errors.migrated_timestamp?.message ? `${errors.migrated_timestamp?.message}` : undefined}
					margin="normal"
					fullWidth
					label="MigratedTimestamp"
					name="migrated_timestamp"
				/>
			</Box>
		</Create>
	);
};

import { Button, FormControlLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { List, useDataGrid } from '@refinedev/mui';
import { IAssignmentQuestion } from 'interfaces';
import React from 'react';

const columns: GridColDef<any>[] = [
	{
		field: 'id',
		headerName: 'UserId',
		type: 'number',
		width: 100,
		renderCell: ({ row }: any) => {
			return row.id + '';
		},
	},
	{
		field: 'first_name',
		headerName: 'Name',
		minWidth: 150,
		flex: 1,
	},
	{
		field: 'section',
		headerName: 'Section',
		minWidth: 100,
		flex: 1,
		sortable: false,
	},
	{
		field: 'affiliate_group',
		headerName: 'Group',
		minWidth: 100,
		flex: 1,
		sortable: false,
	},
	{
		field: 'link_on',
		headerName: 'LinkEnabled',
		minWidth: 100,
		flex: 1,
		sortable: false,
	},
	{
		field: 'chat_start_time',
		headerName: 'ChatTime',
		minWidth: 100,
		flex: 1,
	},
	{
		field: 'current_series_uuid',
		headerName: 'CurrentSeries',
		minWidth: 150,
		flex: 1,
		sortable: false,
	},
	{
		field: 'current_series_info.title',
		headerName: 'CurrentSeriesInfo',
		minWidth: 150,
		flex: 1,
		sortable: false,
		renderCell: ({ row }: any) => {
			return row.current_series_info ? row.current_series_info.title + '' : '';
		},
	},
	{
		field: 'institute_name',
		headerName: 'Institute',
		minWidth: 100,
		flex: 1,
		sortable: false,
	},
	{
		field: 'whatsapp_numbers',
		headerName: 'WhatsappNos',
		minWidth: 200,
		flex: 1,
		sortable: false,
	},
	{
		field: 'last_assignment_code',
		headerName: 'TodayAssignmentCode',
		minWidth: 100,
		flex: 1,
		filterable: false,
		sortable: false,
	},
	{
		field: 'message_report_sent_at',
		headerName: 'LastMsgSentAt',
		minWidth: 150,
		flex: 1,
		filterable: false,
		sortable: false,
		renderCell: ({ row }: any) => {
			return row.message_reports ? row.message_reports.map((e: any) => e.sent_at).join(', ') : '';
		},
	},
	{
		field: 'message_report_status',
		headerName: 'LastMsgStatus',
		minWidth: 150,
		flex: 1,
		filterable: false,
		sortable: false,
		renderCell: ({ row }: any) => {
			return row.message_reports ? row.message_reports.map((e: any) => e.status).join(', ') : '';
		},
	},
];

export const ActiveUserList: React.FC = () => {
	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		pagination: {
			current: 1,
			pageSize: 25,
		},
		sorters: {
			initial: [{ field: 'id', order: 'desc' }],
		},
		filters: {
			initial: [{ field: 'link_on', operator: 'eq', value: true }],
			defaultBehavior: 'merge',
		},
		syncWithLocation: true,
	});

	const handleFilter = () => {
		window.location.href = '/users/active_users';
	};

	return (
		<List>
			<FormControlLabel label="" control={<Button onClick={handleFilter}>Clear</Button>} />
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

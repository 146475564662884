import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const StudentAttendanceEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.user_id}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="UserId"
					name="user_id"
					autoFocus
				/>
				<TextField
					{...register('attendance_date', {
						valueAsDate: true,
					})}
					error={!!errors.attendance_date}
					helperText={errors.attendance_date?.message ? `${errors.attendance_date?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.attendance_date}
					margin="normal"
					type="date"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="AttendanceDate"
					name="attendance_date"
				/>
				<TextField
					{...register('regular_star', {
						valueAsNumber: true,
					})}
					error={!!errors.regular_star}
					helperText={errors.regular_star?.message ? `${errors.regular_star?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.regular_star}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="RegularStar"
					name="regular_star"
					type="number"
				/>
				<TextField
					{...register('additional_star', {
						valueAsNumber: true,
					})}
					error={!!errors.additional_star}
					helperText={errors.additional_star?.message ? `${errors.additional_star?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.additional_star}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="AdditionalStar"
					name="additional_star"
					type="number"
				/>
			</Box>
		</Edit>
	);
};

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

import { IAssignmentQuestion } from 'interfaces';

export const PaymentsList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'user_id',
			headerName: 'User id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.user_id + '';
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'plan_id',
			headerName: 'Plan ID',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'duration_type',
			headerName: 'Duration type',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'duration_time',
			headerName: 'Duration time',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'effective_date',
			headerName: 'Effective date',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'due_date',
			headerName: 'Due date',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'payment_date',
			headerName: 'Payment date',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'buffer_days',
			headerName: 'Buffer days',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'transaction_id',
			headerName: 'Transaction ID',
			minWidth: 350,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				if (row.isEditable === true) {
					return <EditButton hideText recordItemId={row.id} />;
				}
				return null;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'user_id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

type MathContentProps = {
	content?: string;
};

const MathContent: React.FC<MathContentProps> = (props: any) => {
	const { content } = props;
	if (!content || content.length === 0) {
		return null;
	}
	return content && typeof content === 'string' && content.trim().includes('$') ? (
		<ReactMarkdown remarkPlugins={[gfm, remarkMath]} rehypePlugins={[rehypeRaw, rehypeKatex]} children={content} className={'question-content'} />
	) : (
		content
	);
};
export default MathContent;

import useSeriesData from '@hooks/useSeriesData';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const ModulesCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		control,
		formState: { errors },
	} = useForm();

	const seriesList = useSeriesData();

	// (VIP, Non VIP, HOTS)

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('name', {
						required: 'Required',
					})}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
					autoFocus
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					defaultValue={'pitstop'}
					render={({ field }) => (
						<Autocomplete
							options={['pitstop', 'practice']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				{seriesList ? (
					<Controller
						control={control}
						name="series_id"
						render={({ field }) => (
							<Autocomplete
								{...field}
								options={seriesList}
								onChange={(_, value) => {
									field.onChange(value?.id);
								}}
								getOptionLabel={(item) => {
									return seriesList?.find((p: any) => p?.id?.toString() === item?.id?.toString())?.name ?? '';
								}}
								isOptionEqualToValue={(option, value) =>
									value === undefined || option?.id?.toString() === (value?.id ?? value)?.toString()
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="series_id"
										margin="normal"
										variant="outlined"
										error={!!errors?.series_id}
										helperText={errors.series_id?.message ? `${errors.series_id?.message}` : undefined}
										required
									/>
								)}
							/>
						)}
					/>
				) : null}
				{/* VIP, Non VIP, HOTS */}
				<Controller
					control={control}
					name="difficulty_type"
					defaultValue={null}
					render={({ field }) => (
						<Autocomplete
							options={['VIP', 'NON_VIP', 'HOTS', 'None']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Difficulty Type'}
									margin="normal"
									variant="outlined"
									error={!!errors.difficulty_type}
									helperText={errors.difficulty_type?.message ? `${errors.difficulty_type?.message}` : undefined}
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('info')}
					error={!!errors.info}
					helperText={errors.info?.message ? `${errors.info?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					name="info"
				/>
				<Controller
					control={control}
					name="lang"
					rules={{
						required: 'Required',
					}}
					defaultValue={'he'}
					render={({ field }) => (
						<Autocomplete
							options={['he', 'en', 'hi', 'all']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={'Lang'}
									margin="normal"
									variant="outlined"
									error={!!errors.lang}
									helperText={errors.lang?.message ? `${errors.lang?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('ref_order', {
						valueAsNumber: true,
					})}
					error={!!errors.ref_order}
					helperText={errors.ref_order?.message ? `${errors.ref_order?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefOrder"
					name="ref_order"
					type="number"
				/>
			</Box>
		</Create>
	);
};

import { useState } from 'react';
import ariselogo from '../../assets/Group 31.png';
import footerimg from '../../assets/Group 62.png';
import stars from '../../assets/star filled.png';
import '../../styles/main.css';
import ToastHelper from '@helpers/toast_helper';
import APIHelper from '@helpers/api_helper';

const LeaderBoardPage = () => {
	const [student, setStudent] = useState([]);
	const [imgSrc, setImgSrc] = useState('');
	const [date, setDate] = useState();
	const [title, setTitle] = useState('');
	const [component, setComponent] = useState(false);
	const [institute, setinstitute] = useState('ngs');
	const [section, setsection] = useState('A');
	const [start_date, setstart_date] = useState('');
	const [end_date, setend_date] = useState('');
	//const {institute, section, start_date, end_date} = useParams();

	const fetchStudents = (institute: any, section: any, start_date: any, end_date: any) => {
		new ToastHelper().showInfo('Loading leaderboard');
		new APIHelper()
			.fetchLearderBoard(institute, section, start_date, end_date)
			.then((data: any) => {
				setStudent(data.sections[0].records as any);
				setImgSrc(data.institute_logo as any);
				setDate(data.date_range as any);
				setTitle(data.top_title as any);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of students right now. Please try again after sometime.');
			});
	};

	return component ? (
		<div className="bdy">
			<div className="main">
				<div className="header">
					<img src={imgSrc} alt="" />
					<img src={ariselogo} alt="" />
				</div>
				<div className="date_info">
					<b>{title}</b>
					<p>{date}</p>
				</div>
				<div className="student_info">
					<div className="student_list">
						<p>Student</p>
						<i>Stars</i>
					</div>
					{student.map((el: any, i: any) => (
						<div className="student_list" key={i}>
							<p>{el?.student_name}</p>
							<img src={stars} alt="" />
							<b>{el?.star_value}</b>
						</div>
					))}
				</div>
				<div className="footer">
					<img className="footerimg" src={footerimg} alt="" />
				</div>
			</div>
		</div>
	) : (
		<div className="input_leaderboard">
			<label>School: </label>
			<select className="leaderboard_options" onChange={(event: any) => setinstitute(event.target.value)}>
				<option onChange={() => console.log('select')} value="ngs">
					ngs
				</option>
				<option value="sanmati">sanmati</option>
			</select>
			<label>Section: </label>
			<select className="leaderboard_options" onChange={(event: any) => setsection(event.target.value)}>
				<option value="A">A</option>
				<option value="A">G</option>
				<option value="A">H</option>
			</select>
			<label>Start date: </label>
			<input className="leaderboard_options" type="date" onSelect={(event: any) => setstart_date(event.target.value)} />
			<label>End date: </label>
			<input className="leaderboard_options" type="date" onSelect={(event: any) => setend_date(event.target.value)} />
			<button
				className="leaderboard_btn"
				onClick={() => {
					fetchStudents(institute, section, start_date, end_date);
					setComponent(true);
				}}
			>
				Submit
			</button>
		</div>
	);
};

export default LeaderBoardPage;

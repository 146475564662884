import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const ModuleEntitiesCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
	} = useForm();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('module_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.module_id}
					helperText={errors.module_id?.message ? `${errors.module_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="ModuleId"
					name="module_id"
					autoFocus
					type="number"
				/>
				<TextField
					{...register('ref_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.ref_id}
					helperText={errors.ref_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefId"
					name="ref_id"
					type="number"
				/>
				<TextField
					{...register('ref_type', {
						required: 'Required',
					})}
					error={!!errors.ref_type}
					helperText={errors.ref_type?.message ? `${errors.ref_type?.message}` : undefined}
					margin="normal"
					fullWidth
					label="RefType"
					name="ref_type"
				/>
			</Box>
		</Create>
	);
};

import React from 'react';
import { useDataGrid, List } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const ErrorList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'bulk_request_id',
			headerName: 'BulkRequestId',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'entity_id',
			headerName: 'EntityId',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'entity_type',
			headerName: 'EntityType',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'reason',
			headerName: 'Reason',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			flex: 1,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';

export const UserClassesEdit: React.FC = () => {
	const {
		saveButtonProps,
		refineCore: { queryResult },
		register,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
			<TextField
					type="number"
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.user_id}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="UserId"
					name="user_id"
					autoFocus
				/>
				<TextField
					type="number"
					{...register('institute_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.institute_id}
					helperText={errors.institute_id?.message ? `${errors.institute_id?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.institute_id}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="InstituteId"
					name="institute_id"
					autoFocus
				/>
				<TextField
					{...register('class_code')}
					error={!!errors.class_code}
					helperText={errors.class_code?.message ? `${errors.class_code?.message}` : undefined}
					defaultValue={queryResult?.data?.data?.class_code}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="ClassCode"
					name="class_code"
				/>
			</Box>
		</Edit>
	);
};

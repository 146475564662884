import Box from '@mui/material/Box'
import { LayoutProps } from '@refinedev/core';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Header as DefaultHeader } from '../header';
import { Sider as DefaultSider } from '../sider';

export const Layout: React.FC<LayoutProps> = ({ Sider, Header, Footer, OffLayoutArea, children }) => {
	const SiderToRender = Sider ?? DefaultSider;
	const HeaderToRender = Header ?? DefaultHeader;

	return (
		<Box display="flex" flexDirection="row">
			<SiderToRender />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					flexGrow: 1,
					minHeight: '100vh',
					overflowX: 'auto'
				}}
			>
				<HeaderToRender />
				<Box
					component="main"
					sx={{
						p: { xs: 1, md: 2, lg: 3 },
						flexGrow: 1,
						bgcolor: (theme) => theme.palette.background.default,
					}}
				>
					{children}
				</Box>
				{Footer && <Footer />}
			</Box>
			{OffLayoutArea && <OffLayoutArea />}
			<ToastContainer />
		</Box>
	);
};

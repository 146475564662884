import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { IAssignmentQuestion } from 'interfaces';

export const StudentAttendanceList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'user_id',
			headerName: 'UserId',
			type: 'number',
			width: 100,
			renderCell: ({ row }: any) => {
				return row.user_id + '';
			},
		},
		{
			field: 'name',
			headerName: 'UserName',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'attendance_date',
			headerName: 'AttendanceDate',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'regular_star',
			headerName: 'RegularStar',
			minWidth: 150,
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: ({ row }: any) => {
				return row.regular_star ? row.regular_star + '' : 'NA';
			},
		},
		{
			field: 'additional_star',
			headerName: 'AdditionalStar',
			minWidth: 150,
			flex: 1,
			filterable: false,
			sortable: false,
			renderCell: ({ row }: any) => {
				return row.additional_star ? row.additional_star + '' : 'NA';
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} />
		</List>
	);
};

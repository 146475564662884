import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DeleteButton } from '@refinedev/mui';

const columns: GridColDef<any>[] = [
	{
		field: 'id',
		headerName: 'Id',
		type: 'number',
		width: 100,
		renderCell: ({ row }: any) => {
			return row.id + '';
		},
	},
	{
		field: 'name',
		headerName: 'Name',
		width: 150,
	},
	{
		field: 'assignment_code',
		headerName: 'AssignmentCode',
		width: 150,
	},
	{
		field: 'invitation_type',
		headerName: 'InvitationType',
		width: 100,
	},
	{
		field: 'info',
		headerName: 'Info',
		width: 150,
	},
	{
		field: 'mother_contact',
		headerName: 'MotherContact',
		width: 150,
	},
	{
		field: 'father_contact',
		headerName: 'FatherContact',
		width: 150,
	},
	{
		field: 'target_time',
		headerName: 'TargetTime',
		width: 100,
	},
	{
		field: 'target_date',
		headerName: 'TargetDate',
		width: 100,
	},
	{
		field: 'last_report.sent_at',
		headerName: 'LastMsgSentAt',
		minWidth: 150,
		flex: 1,
		filterable: false,
		sortable: false,
		renderCell: ({ row }: any) => {
			return row.last_report ? row.last_report.sent_at : '';
		},
	},
	{
		field: 'last_report.status',
		headerName: 'LastMsgStatus',
		minWidth: 150,
		flex: 1,
		filterable: false,
		sortable: false,
		renderCell: ({ row }: any) => {
			return row.last_report ? row.last_report.status : '';
		},
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: 'Actions',
		renderCell: function render({ row }) {
			// return <><EditButton hideText recordItemId={row.id} /><ShowButton hideText recordItemId={row.id}/></>;
			return (
				<>
					<EditButton hideText recordItemId={row.id} />
					<DeleteButton hideText recordItemId={row.id} />
				</>
			);
		},
		align: 'center',
		headerAlign: 'center',
		minWidth: 150,
	},
];


export const AssignmentInvitationList: React.FC = () => {

	const { dataGridProps } = useDataGrid<any>({
		pagination: {
			current: 1,
			pageSize: 25
		},
		sorters: {
			initial: [{ field: "id", order: "desc" }],
		},
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};

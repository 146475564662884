import { Box, TextField, Autocomplete, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Edit } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const UserContactCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		control,
		formState: { errors },
	} = useForm();

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.ref_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="UserId"
					name="user_id"
					autoFocus
				/>
				<Controller
					control={control}
					name="type"
					rules={{
						required: 'Required',
					}}
					render={({ field }) => (
						<Autocomplete
							options={['phone', 'email']}
							{...field}
							onChange={(_, value) => {
								field.onChange(value);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Type"
									margin="normal"
									variant="outlined"
									error={!!errors.type}
									helperText={errors.type?.message ? `${errors.type?.message}` : undefined}
									required
								/>
							)}
						/>
					)}
				/>
				<TextField
					{...register('country_code', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.country_code}
					helperText={errors.country_code?.message ? `${errors.country_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="CountryCode (0 in case of email)"
					type="number"
					name="country_code"
				/>
				<TextField
					{...register('info', {
						required: 'Required',
					})}
					error={!!errors.country_code}
					helperText={errors.country_code?.message ? `${errors.country_code?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Info"
					type="text"
					name="info"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('comms_opted')} />} label="CommsOpted" />
				</FormGroup>
			</Box>
		</Edit>
	);
};

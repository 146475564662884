/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import APIHelper from '../../helpers/api_helper';
import ToastHelper from '../../helpers/toast_helper';
import { QuestionItemForm } from './../../components/QuestionItemForm';
import { useParams } from 'react-router-dom';
import { List } from '@refinedev/mui';

export const AllQuestionPage = () => {
	const [questions, setQuestions] = useState([]);
	const { grade, subject, book, chapter } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchQuestions(grade, subject, book, chapter);
	}, []);

	const fetchQuestions = (grade: any, subject: any, book: any, chapter: any) => {
		new ToastHelper().showInfo('Loading Questions');
		new APIHelper()
			.fetchQuestions(grade, subject, book, chapter)
			.then((data: any) => {
				setQuestions(data);
			})
			.catch(() => {
				new ToastHelper().showError('Oops! Error in fetching list of questions right now. Please try again after sometime.');
			});
	};

	return (
		<div data-component="AllQuestionPage">
			<div className={'questions'}>
				<div>
					<b>
						Class: {grade}, Subject: {subject}, Book: {book} Chapter: {chapter}
					</b>
				</div>
				<List>
					<table style={{ border: 1 }}>
						<thead>
							<tr>
								<td>Labels</td>
								<td></td>
								<td>ID</td>
								<td className={'tbl-title'}>Topic</td>
								<td>Order</td>
								<td className={'tbl-descp'}>Description</td>
								<td>Type</td>
								<td>Option1</td>
								<td>Option2</td>
								<td>Option3</td>
								<td>Option4</td>
								<td>Difficulty Lvl</td>
								<td>Avg Time Taken</td>
								<td>Key Concepts</td>
								<td>Other Concepts</td>
								<td>Approved</td>
								<td>Submit</td>
							</tr>
						</thead>
						<tbody>
							{questions.map((e, i) => {
								return <QuestionItemForm input={e} index={i} key={i} />;
							})}
						</tbody>
					</table>
				</List>
			</div>
		</div>
	);
};

import { IGasQuestion } from '@interfaces/index';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditButton, List, useDataGrid } from '@refinedev/mui';
import React from 'react';

export const PlanList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'id',
			headerName: 'Id',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.id + '';
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 150,
		},
		{
			field: 'title',
			headerName: 'Title',
			width: 150,
		},
		{
			field: 'duration',
			headerName: 'Duration',
			minWidth: 150,
		},
		{
			field: 'base_price',
			headerName: 'Base Price',
			minWidth: 150,
		},
		{
			field: 'discounted_price',
			headerName: 'Discounted Price',
			minWidth: 150,
		},
		{
			field: 'start_date',
			headerName: 'Start Date',
			minWidth: 150,
		},
		{
			field: 'end_date',
			headerName: 'End Date',
			minWidth: 150,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IGasQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};
